import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProduct, addReview, getReviews, loadOrderHistory, getOrderCountForProduct } from '../services/firestoreService';
import ReactStars from 'react-rating-stars-component';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useCart } from '../contexts/CartContext';
import Modal from 'react-modal';
import { useSpring, animated } from '@react-spring/web';
import flowerIcon from '../images/flower.ico'; // Flower icon for animation
import styles from './ProductDetails.module.css'; // Import the CSS module
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
import { Carousel } from 'react-responsive-carousel'; // Carousel component for multiple images

const ProductDetails = ({ toggleCartSidebar }) => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ rating: 0, text: '' });
  const [user, setUser] = useState(null);
  const [hasPurchased, setHasPurchased] = useState(false);
  const { addToCart } = useCart();
  const [selectedOption, setSelectedOption] = useState(null); // Track selected option
  const [isAnimating, setIsAnimating] = useState(false); // Track animation state
  const [animationPosition, setAnimationPosition] = useState({ fromX: 0, fromY: 0, toX: 0, toY: 0 }); // Store animation position
  const [modalProduct, setModalProduct] = useState(null); // Handle modal for missing option
  const [tarjetaModalProduct, setTarjetaModalProduct] = useState(null); // Handle tarjeta message modal
  const [tarjetaMessage, setTarjetaMessage] = useState(''); // State for "Tarjeta" message

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProduct(productId);
        setProduct({ ...productData, id: productId });
      } catch (err) {
        console.error('Failed to fetch product:', err);
      }
    };

    const fetchReviews = async () => {
      try {
        const reviewsList = await getReviews(productId);
        setReviews(reviewsList);
      } catch (err) {
        console.error('Failed to fetch reviews:', err);
      }
    };

    const fetchOrderHistory = async (userId) => {
      const unsubscribe = loadOrderHistory((orders) => {
        const purchasedProducts = orders.flatMap(order => order.items.map(item => item.id));
        setHasPurchased(purchasedProducts.includes(productId));
      });

      return () => unsubscribe();
    };

    fetchProduct();
    fetchReviews();

    const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        await fetchOrderHistory(currentUser.uid);
      }
    });

    return () => unsubscribeAuth();
  }, [productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prevReview) => ({ ...prevReview, [name]: value }));
  };

  const handleRatingChange = (newRating) => {
    setNewReview((prevReview) => ({ ...prevReview, rating: newRating }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      alert('You need to be logged in to add a review.');
      return;
    }

    try {
      const userDocRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        console.error('User document does not exist');
        return;
      }

      const name = userDoc.data().name;
      if (!name) {
        console.error('User name is undefined in Firestore document');
        return;
      }

      // Check for existing reviews by the user for this product
      const orderID = await getOrderCountForProduct(productId, user.uid); // Fetch order ID for this product
      const existingReview = reviews.find(review => review.orderID === orderID);
      if (existingReview) {
        alert('You have already reviewed this product for this order. Make a new purchase to leave another review.');
        return;
      }

      const reviewToSubmit = {
        ...newReview,
        name: name,
        orderID: orderID // Include orderID in the review
      };

      await addReview(productId, reviewToSubmit);
      setReviews((prevReviews) => [...prevReviews, reviewToSubmit]);
      setNewReview({ rating: 0, text: '', name: '' });
    } catch (err) {
      console.error('Failed to get user name or add review:', err);
    }
  };

  const startAnimation = (productElement) => {
    const cartIcon = document.getElementById('cart-icon');
    if (cartIcon && productElement) {
      const productPosition = productElement.getBoundingClientRect();
      const cartPosition = cartIcon.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollX = window.scrollX || window.pageXOffset;

      setAnimationPosition({
        fromX: productPosition.x + scrollX,
        fromY: productPosition.y + scrollY,
        toX: cartPosition.x + scrollX,
        toY: cartPosition.y + scrollX,
      });

      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 1000);
    }
  };

  const flowerAnimation = useSpring({
    from: { left: `${animationPosition.fromX}px`, top: `${animationPosition.fromY}px`, opacity: 1 },
    to: { left: `${animationPosition.toX}px`, top: `${animationPosition.toY}px`, opacity: 0 },
    config: { duration: 1000 },
    reset: isAnimating,
    onRest: () => setIsAnimating(false),
  });

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleAddToCart = (product, event) => {
    const productElement = event.currentTarget.closest(`.${styles.productContainer}`);
    const option = selectedOption;
    const quantity = 1; // Default quantity

    // Check if product has options, open modal if not selected
    if (product.options && product.options.length > 0 && !option) {
      setModalProduct(product);
      return;
    }

    // Check if product has "Tarjeta" and open "Tarjeta" modal if applicable
    if (product.hasTarjeta) {
      setTarjetaModalProduct(product);
      return;
    }

    const selectedProduct = {
      ...product,
      price: option ? option.price : product.price,
      option: option || null,
      tarjetaMessage: null, // No tarjeta message needed if not applicable
      quantity,
    };

    startAnimation(productElement);
    addToCart(selectedProduct, toggleCartSidebar);
  };

  const handleModalAddToCart = () => {
    const product = modalProduct;
    const option = selectedOption;

    // Validation: Ensure a valid option is selected in the modal
    if (product.options && product.options.length > 0 && (!option || option === "")) {
      alert('Please select a valid option.');
      return;
    }

    // Check for "Tarjeta" after adding option
    if (product.hasTarjeta) {
      setTarjetaModalProduct(product); // Open "Tarjeta" modal
      setModalProduct(null); // Close options modal
    } else {
      const selectedProduct = {
        ...product,
        price: option ? option.price : product.price,
        option,
        tarjetaMessage: null,
        quantity: 1,
      };

      addToCart(selectedProduct, toggleCartSidebar); 
      setModalProduct(null);
    }
  };

  const handleTarjetaSave = () => {
    const product = tarjetaModalProduct;

    const selectedProduct = {
      ...product,
      price: product.price,
      option: selectedOption || null, // Use selected option if available
      tarjetaMessage, // Include the tarjeta message
      quantity: 1,
    };

    startAnimation(document.getElementById(`product-${product.id}`)); // Animate product to cart
    addToCart(selectedProduct, toggleCartSidebar);
    setTarjetaModalProduct(null); // Close tarjeta modal after saving
    setTarjetaMessage(''); // Reset tarjeta message
  };

  const handleTarjetaSkip = () => {
    const product = tarjetaModalProduct;

    const selectedProduct = {
      ...product,
      price: product.price,
      option: selectedOption || null, // Use selected option if available
      tarjetaMessage: null, // Skip tarjeta message
      quantity: 1,
    };

    startAnimation(document.getElementById(`product-${product.id}`)); // Animate product to cart
    addToCart(selectedProduct, toggleCartSidebar); 
    setTarjetaModalProduct(null); // Close tarjeta modal after skipping
    setTarjetaMessage(''); // Reset tarjeta message
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  // Ensure principal image is shown first in the carousel
  const imageUrls = product.imageUrls && product.principalImageUrl 
    ? [product.principalImageUrl, ...product.imageUrls.filter(url => url !== product.principalImageUrl)] 
    : product.imageUrls;

  return (
    <div className={styles.productContainer}>
      <h2 className={styles.productTitle}>{product.name}</h2>

      {/* Display product images in a carousel if there are multiple images */}
      {imageUrls && imageUrls.length > 1 ? (
        <Carousel showArrows={true} showThumbs={false} infiniteLoop useKeyboardArrows>
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              <img src={imageUrl} alt={`Product image ${index + 1}`} className={`img-fluid ${styles.productImage}`} />
            </div>
          ))}
        </Carousel>
      ) : (
        <img src={product.principalImageUrl} alt={product.name} className={`img-fluid ${styles.productImage}`} />
      )}

      {/* Display both short and full description */}
      <p className={styles.productShortDescription}>
        {product.shortDescription}
      </p>
      <p className={styles.productFullDescription}>
        {product.fullDescription}
      </p>

      <p className={styles.productPrice}>Precio: {formatCurrency(product.price)}</p>
      <p>Categoría: {product.category}</p>
      <p className={`${styles.productStock} ${product.stock > 0 ? 'inStock' : 'outStock'}`}>
        {product.stock > 0 ? 'Disponible' : 'Agotado'}
      </p>

      {product.options && product.options.length > 0 && (
        <select
          onChange={(e) => handleOptionSelect(JSON.parse(e.target.value))}
          className="form-control mt-2"
        >
          <option value="" disabled>Select an option</option> {/* Disable placeholder */}
          {product.options.map((opt, index) => (
            <option key={index} value={JSON.stringify(opt)}>
              {opt.name} - {formatCurrency(opt.price)}
            </option>
          ))}
        </select>
      )}

      <button onClick={(e) => handleAddToCart(product, e)} className={`btn btn-primary mt-3 ${styles.productButton}`} disabled={product.stock <= 0}>
        {product.stock > 0 ? 'Agregar al Carrito' : 'Agotado'}
      </button>

      {isAnimating && (
        <animated.img src={flowerIcon} style={flowerAnimation} className={styles.flowerAnimation} />
      )}

      <h3 className={styles.reviewTitle}>Reviews</h3>
      <ul className="list-group mb-4">
        {reviews.map((review, index) => (
          <li key={index} className={styles.listGroupItem}>
            <strong>Calificación: </strong>
            <ReactStars count={5} value={review.rating} edit={false} size={24} activeColor="#ffd700" />
            <br />
            <strong>Review: </strong>{review.text}
            <br />
            <strong>From: </strong>{review.name}
          </li>
        ))}
      </ul>

      {hasPurchased ? (
        <div className={styles.reviewSection}>
          <h3>Agregar Review</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Calificación</label>
              <ReactStars count={5} onChange={handleRatingChange} size={24} activeColor="#ffd700" value={newReview.rating} />
            </div>
            <div className="form-group">
              <label>Comentario</label>
              <textarea name="text" value={newReview.text} onChange={handleInputChange} className="form-control" required />
            </div>
            <button type="submit" className="btn btn-primary mt-2">Enviar Review</button>
          </form>
        </div>
      ) : (
        <p>Sólo puedes hacer reseñas de productos que hayas comprado.</p>
      )}

      {/* Modal for product options */}
      <Modal
        isOpen={!!modalProduct}
        onRequestClose={() => setModalProduct(null)}
        contentLabel="Select Product Option"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '450px', // Small width
            height: 'auto',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darker overlay for focus
          },
        }}
      >
        <div className={styles.modalContent}>
          <h4>Selecciona una opción {modalProduct?.name}</h4>
          <select
            onChange={(e) => handleOptionSelect(JSON.parse(e.target.value))}
            className="form-control mt-2"
          >
            <option value="" disabled selected>Select an option</option> {/* Disable placeholder */}
            {modalProduct?.options.map((opt, index) => (
              <option key={index} value={JSON.stringify(opt)}>
                {opt.name} - {formatCurrency(opt.price)}
              </option>
            ))}
          </select>
          <button className="btn btn-primary mt-3" onClick={handleModalAddToCart}>
            Add to Cart
          </button>
        </div>
      </Modal>

      {/* Modal for "Tarjeta" message */}
      <Modal
        isOpen={!!tarjetaModalProduct}
        onRequestClose={() => setTarjetaModalProduct(null)}
        contentLabel="Enter Tarjeta Message"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '450px',
            height: 'auto',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darker overlay for focus
          },
        }}
      >
        <div className={styles.modalContent}>
          <h4>Escribe tu mensaje para la tarjeta</h4>
          <input
            type="text"
            id={`tarjeta-modal-message`}
            className="form-control"
            placeholder="Tu mensaje..."
            value={tarjetaMessage}
            onChange={(e) => setTarjetaMessage(e.target.value)}
          />
          <div className="mt-3 d-flex justify-content-between">
            <button className="btn btn-primary" onClick={handleTarjetaSave}>
              Guardar Mensaje
            </button>
            <button className="btn btn-secondary" onClick={handleTarjetaSkip}>
              Saltar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductDetails;
