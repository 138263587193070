import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactStars from 'react-rating-stars-component';
import styles from './Eventos.module.css';
import avatar1 from '../assets/avatar1.jpg'; 
import avatar2 from '../assets/avatar2.jpg';
import avatar3 from '../assets/avatar3.jpg';
import avatar4 from '../assets/avatar4.jpg';
import avatar5 from '../assets/avatar5.jpg';
import avatar6 from '../assets/avatar6.jpg';
import avatar7 from '../assets/avatar7.jpg';
import avatar8 from '../assets/avatar8.jpg';
import avatar9 from '../assets/avatar9.jpg';
import avatar10 from '../assets/avatar10.jpg';
import avatar11 from '../assets/avatar11.jpg';
import avatar12 from '../assets/avatar12.jpg';
import { getReviewsForAllProducts } from '../services/firestoreService'; // Ensure this is imported

const Eventos = () => {
  const [eventosContent, setEventosContent] = useState(null);
  const [sectionsProducts, setSectionsProducts] = useState({});
  const [reviews, setReviews] = useState([]);
  const firestore = getFirestore();

  const avatars = [
    avatar1, avatar2, avatar3, avatar4, avatar5, avatar6,
    avatar7, avatar8, avatar9, avatar10, avatar11, avatar12
  ];

  useEffect(() => {
    const fetchEventosContent = async () => {
      const currentContentDoc = await getDoc(doc(firestore, 'eventosContent', 'current'));
      if (currentContentDoc.exists()) {
        const activeContentId = currentContentDoc.data().activeContent;
        if (activeContentId) {
          const activeContentDoc = await getDoc(doc(firestore, 'eventosContent', activeContentId));
          if (activeContentDoc.exists()) {
            const contentData = activeContentDoc.data();
            setEventosContent(contentData);

            if (contentData.sections) {
              const productsData = {};
              for (let section of contentData.sections) {
                if (section.type === 'productList') {
                  let productsList = [];
                  switch (section.content.option) {
                    case 'bestSelling':
                      productsList = await getBestSellingProducts(firestore);
                      break;
                    case 'recentlyAdded':
                      productsList = await getRecentlyAddedProducts(firestore);
                      break;
                    default:
                      productsList = await getProductsByCategory(firestore, section.content.option);
                      break;
                  }
                  productsData[section.title] = productsList;
                }
              }
              setSectionsProducts(productsData);
            }
          }
        }
      }
    };

    const fetchReviews = async () => {
      try {
        const allProductReviews = await getReviewsForAllProducts(firestore);
        setReviews(allProductReviews);
      } catch (error) {
        console.error('Failed to fetch reviews:', error);
      }
    };

    fetchEventosContent();
    fetchReviews(); // Fetch all reviews
  }, [firestore]);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  const [expandedReview, setExpandedReview] = useState(null); // Track which review is expanded
  const toggleReviewExpansion = (id) => {
    setExpandedReview(expandedReview === id ? null : id); // Toggle the expansion state
  };

  return (
    <div>
      {eventosContent && (
        <div>
          <div className={styles.heroSection}>
            <img src={eventosContent.heroImage} className={styles.heroImage} alt="Hero section" />
            <div className={styles.heroText}>
              {eventosContent.heroText}
            </div>
          </div>
          <div className={styles.eventosContent}>
            <h1>{eventosContent.title}</h1>
            <p>{eventosContent.description}</p>
  
            {eventosContent.sections && eventosContent.sections.map((section, index) => (
              <div key={index} className={styles.section}>
                <h2>{section.title}</h2>
  
                {/* Product List Section */}
                {section.type === 'productList' && (
                  <div className={styles.carouselContainer}>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                      {(sectionsProducts[section.title] || []).map(product => (
                        <div key={product.id} className={styles.carouselItem}>
                          <img src={product.principalImageUrl} alt={product.name} className={styles.carouselImage} />
                          <div className={styles.carouselCaption}>
                            <h5>{product.name}</h5>
                            <p>{product.shortDescription}</p>
                            <p><strong>{formatCurrency(product.price)}</strong></p>
                            <p>Category: {product.category}</p>
                            <p>Stock: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
                            <Link to={`/products/${product.id}`}>
                              <button className="btn btn-secondary mt-2">View Product</button>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                    <div className={styles.seeAllContainer}>
                      <Link to="/products">
                        <button className="btn btn-primary mt-3">See All Products</button>
                      </Link>
                    </div>
                  </div>
                )}
  
                {/* Normal Section */}
                {section.type === 'normal' && (
                  <div>
                    <img src={section.content.image} className={styles.sectionImage} alt={section.title} />
                    <p>{section.content.text}</p>
                  </div>
                )}
  
                {/* On Image Section */}
                {section.type === 'onImage' && (
                  <div className={styles.onImageSection}>
                    <img src={section.content.image} className={styles.onImageSectionImage} alt={section.title} />
                    <div
                      className={styles.onImageSectionText}
                      style={{ color: section.content.textColor }}
                    >
                      {section.content.text}
                    </div>
                  </div>
                )}
  
                {/* Carousel Section */}
                {section.type === 'carousel' && (
                  <div className={styles.carouselContainer}>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                      {section.content.images && section.content.images.map((image, idx) => (
                        <div key={idx} className={styles.carouselItem}>
                          <img src={image} className={styles.carouselImage} alt={`carousel-${idx}`} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )}
  
                {/* Reviews Section */}
                {section.type === 'reviews' && reviews.length > 0 && (
                  <div className={styles.carouselContainer}>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                      {reviews.map((review, idx) => (
                        <div key={review.id} className={styles.reviewCard}>
                          <img
                            src={avatars[idx % avatars.length]} // Rotate through avatars
                            alt="Reviewer avatar"
                            className={styles.avatar}
                          />
                          <div className={styles.reviewContent}>
                            <h5>{review.userName}</h5>
                            <p>
                              {expandedReview === review.id ? review.text : review.text.substring(0, 100)}
                              {review.text.length > 100 && (
                                <span
                                  className={styles.readMore}
                                  onClick={() => toggleReviewExpansion(review.id)}>
                                  {expandedReview === review.id ? ' Read Less' : '... Read More'}
                                </span>
                              )}
                            </p>
                            <ReactStars
                              count={5}
                              value={review.rating}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )}

                {section.type === 'text' && (
                  <div className={styles.textSection}>
                    <p>{section.content.text}</p>
                  </div>
                )}

                {section.type === 'button' && (
                  <div className={styles.buttonSection}>
                    <Link to="/products">
                      <button className="btn btn-primary">Click para Comprar</button>
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Updated formatCurrency to format prices in Costa Rican colones (₡)
const formatCurrency = (value) => {
  return new Intl.NumberFormat('es-CR', { 
    style: 'currency', 
    currency: 'CRC', 
    minimumFractionDigits: 0 
  }).format(value).replace('CRC', '₡').replace(',', ' ');
};

const getBestSellingProducts = async (firestore) => {
  const productsCollection = collection(firestore, 'products');
  const productsQuery = query(productsCollection, where('orderCount', '>', 0));
  const querySnapshot = await getDocs(productsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getRecentlyAddedProducts = async (firestore) => {
  const productsCollection = collection(firestore, 'products');
  const productsQuery = query(productsCollection, where('createdAt', '<=', new Date()));
  const querySnapshot = await getDocs(productsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getProductsByCategory = async (firestore, category) => {
  const productsCollection = collection(firestore, 'products');
  const productsQuery = query(productsCollection, where('category', '==', category));
  const querySnapshot = await getDocs(productsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export default Eventos;
