import React, { useState, useEffect } from 'react';
import styles from './PolicyPage.module.css';

const About = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    fetch('/content/about.json')
      .then(response => response.json())
      .then(data => setContent(data))
      .catch(error => console.error('Error fetching terms and conditions:', error));
  }, []);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.policyWrapper}>
      <div className={styles.policyContainer}>
        <h1 className={styles.policyTitle}>{content.title}</h1>
        {content.content.map((section, index) => (
          <div key={index} className={styles.policySection}>
            <h2 className={styles.policySectionTitle}>{section.sectionTitle}</h2>
            <p className={styles.policyText}>{section.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
