// src/components/AdminManagement.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Swal from 'sweetalert2'; // Import SweetAlert2
import withReactContent from 'sweetalert2-react-content'; // For SweetAlert customization
import styles from './AdminManagement.module.css'; // Import CSS module

const MySwal = withReactContent(Swal); // Customize SweetAlert

const AdminManagement = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [admins, setAdmins] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchAdmins = async () => {
      setLoading(true); // Start loading
      const q = collection(firestore, 'admins');
      const querySnapshot = await getDocs(q);
      const adminsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdmins(adminsList);
      setLoading(false); // End loading
    };

    const checkAdmin = async (user) => {
      if (user) {
        const q = collection(firestore, 'admins');
        const querySnapshot = await getDocs(q);
        const adminsList = querySnapshot.docs.map(doc => doc.data().email);
        setIsAdmin(adminsList.includes(user.email));
      }
    };

    fetchAdmins();

    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      checkAdmin(user);
    });
  }, [firestore, auth]);

  const handleAddAdmin = async () => {
    // Email validation regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!adminEmail || !emailPattern.test(adminEmail)) {
      MySwal.fire('Error', 'Por favor, ingresa una dirección de correo válida.', 'warning');
      return;
    }

    setLoading(true); // Start loading spinner
    try {
      await addDoc(collection(firestore, 'admins'), { email: adminEmail });
      setAdminEmail('');
      MySwal.fire('Éxito', '¡Admin agregado exitosamente!', 'success');

      // Refresh the admin list
      const q = collection(firestore, 'admins');
      const querySnapshot = await getDocs(q);
      const adminsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAdmins(adminsList);
    } catch (error) {
      MySwal.fire('Error', 'Hubo un problema al agregar el admin.', 'error');
    } finally {
      setLoading(false); // End loading spinner
    }
  };

  const handleDeleteAdmin = async (id) => {
    MySwal.fire({
      title: '¿Estás seguro?',
      text: 'Esto eliminará al admin de la lista.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, ¡elimínalo!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true); // Start loading spinner
        try {
          await deleteDoc(doc(firestore, 'admins', id));
          MySwal.fire('Eliminado', '¡Admin eliminado exitosamente!', 'success');

          // Refresh the admin list
          const q = collection(firestore, 'admins');
          const querySnapshot = await getDocs(q);
          const adminsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAdmins(adminsList);
        } catch (error) {
          MySwal.fire('Error', 'Hubo un problema al eliminar el admin.', 'error');
        } finally {
          setLoading(false); // End loading spinner
        }
      }
    });
  };

  if (!isAdmin) {
    return <p>No tienes acceso a esta página.</p>;
  }

  return (
    <div className={styles.adminManagementContainer}>
      {loading && <div className={styles.loadingOverlay}></div>} {/* Show loading spinner */}
      <h2 className={styles.adminManagementTitle}>Admin: Manejo de Cuentas</h2>
      {currentUser && <p className={styles.loggedInUser}>Accediste como: {currentUser.email}</p>}
      <div className={styles.inputGroup}>
        <input
          type="email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
          placeholder="Ingresa admin email"
          className={styles.inputField}
        />
        <button className={styles.addButton} onClick={handleAddAdmin}>Agregar Admin</button>
      </div>
      <h3>Admins Actuales:</h3>
      <ul className={styles.adminList}>
        {admins.map((admin) => (
          <li key={admin.id} className={styles.adminItem}>
            <span className={styles.adminEmail}>{admin.email}</span>
            <button className={styles.removeButton} onClick={() => handleDeleteAdmin(admin.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminManagement;
