import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { loadCart, saveCart, updateProductStock, saveOrder, getOrderCount, updateOrderCount } from '../services/firestoreService';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import Modal from 'react-modal';
import styles from './Checkout.module.css';
import provinces from '../data/provinces.json';  // Import provinces from JSON
import cantonPrices from '../data/cantonPrices.json';  // Import canton prices from JSON
import Swal from 'sweetalert2';


const Checkout = ({ toggleCartSidebar }) => {
  const { cart, setCart } = useCart();
  const [recipientEmail, setRecipientEmail] = useState(auth.currentUser ? auth.currentUser.email : '');
  const [shippingMethod, setShippingMethod] = useState('delivery');
  const [contactInfo, setContactInfo] = useState({
    recipientName: '',
    recipientLastName: '',
    telefono: '',
  });
  const [shippingInfo, setShippingInfo] = useState({
    direccion: '',
    provincia: 'San José',
    canton: '',
    distrito: '',
    codigoPostal: '',
    pais: 'Costa Rica',
  });
  const [deliveryPrice, setDeliveryPrice] = useState(0);  // Initial delivery price
  const [saveAccountInfo, setSaveAccountInfo] = useState(false);
  const [saveDeliveryInfo, setSaveDeliveryInfo] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);  // New state for disabling button
  const navigate = useNavigate();

  // Validation patterns
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const namePattern = /^[a-zA-Z\s]+$/;
  const phonePattern = /^[0-9]{8}$/;  // Costa Rican phone number (8 digits)
  const postalCodePattern = /^[0-9]+$/;


        const [deliveryDate, setDeliveryDate] = useState(''); // For date input
        const [deliveryHour, setDeliveryHour] = useState(''); // For hour input
        const [deliveryMinute, setDeliveryMinute] = useState(''); // For minute input
        const [deliveryTimeAMPM, setDeliveryTimeAMPM] = useState('AM'); // To capture AM/PM
        const [additionalComments, setAdditionalComments] = useState(''); // Optional comments


  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(firestore, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setContactInfo({
            recipientName: data.name || '',
            recipientLastName: data.lastName || '',
            telefono: data.telefono || '',
          });
          setShippingInfo({
            direccion: data.direccion || '',
            provincia: data.provincia || 'San José',
            canton: data.canton || '',
            distrito: data.distrito || '',
            codigoPostal: data.codigoPostal || '',
            pais: 'Costa Rica',
          });
          setRecipientEmail(auth.currentUser.email);
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // Update delivery price based on the selected shipping method and canton
    if (shippingMethod === 'pickup') {
      setDeliveryPrice(0);  // No delivery fee for pickup
    } else {
      const price = cantonPrices[shippingInfo.canton] || 0;
      setDeliveryPrice(price);
    }
  }, [shippingInfo.canton, shippingMethod]);

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));

    // Reset canton and distrito when province changes
    if (name === 'provincia') {
      setShippingInfo((prevInfo) => ({ ...prevInfo, canton: '', distrito: '' }));
    }
  };

  const handleEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };


  const validateFields = () => {
    const today = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
    
    // Ensure delivery time is set and valid
    if (!deliveryHour || !deliveryMinute || !deliveryTimeAMPM || deliveryHour === "" || deliveryMinute === "") {
      Swal.fire('Error', 'Por favor, selecciona una hora de entrega válida.', 'error');
      return false;
    }
  
    // Construct the selected time in 24-hour format based on AM/PM selection
    let adjustedHours = parseInt(deliveryHour, 10);
  
    if (deliveryTimeAMPM === 'PM' && adjustedHours !== 12) {
      adjustedHours += 12; // Convert PM times to 24-hour format
    } else if (deliveryTimeAMPM === 'AM' && adjustedHours === 12) {
      adjustedHours = 0; // Convert 12 AM to 00 (midnight)
    }
  
    const selectedTime = `${String(adjustedHours).padStart(2, '0')}:${deliveryMinute.toString().padStart(2, '0')}:00`; // Format to HH:MM:SS
    
    // Validate email
    if (!emailPattern.test(recipientEmail)) {
      Swal.fire('Error', 'Por favor, ingresa un correo electrónico válido.', 'error');
      return false;
    }
  
    // Validate name
    if (!namePattern.test(contactInfo.recipientName)) {
      Swal.fire('Error', 'Por favor, ingresa un nombre válido.', 'error');
      return false;
    }
  
    if (!namePattern.test(contactInfo.recipientLastName)) {
      Swal.fire('Error', 'Por favor, ingresa apellidos válidos.', 'error');
      return false;
    }
  
    // Validate phone number
    if (!phonePattern.test(contactInfo.telefono)) {
      Swal.fire('Error', 'Por favor, ingresa un número de teléfono válido (8 dígitos).', 'error');
      return false;
    }
  
    // Validate address if the shipping method is 'delivery'
    if (shippingMethod === 'delivery' && !shippingInfo.direccion) {
      Swal.fire('Error', 'Por favor, ingresa una dirección válida.', 'error');
      return false;
    }
  
    // Validate delivery date (must not be in the past)
    if (!deliveryDate || deliveryDate < today) {
      Swal.fire('Error', 'Por favor, selecciona una fecha de entrega válida.', 'error');
      return false;
    }
  
    // If delivery is today, validate that the delivery time is in the future
    if (deliveryDate === today) {
      const currentTime = new Date().toTimeString().split(" ")[0]; // Get current time in HH:MM:SS format
      if (selectedTime < currentTime) {
        Swal.fire('Error', 'Por favor, selecciona una hora de entrega válida.', 'error');
        return false;
      }
    }
  
    // Validate payment method
    if (!paymentMethod) {
      Swal.fire('Error', 'Por favor, selecciona un método de pago.', 'error');
      return false;
    }
  
    return true;
};

  

  const handleCheckout = async () => {
    if (!validateFields()) return;

    try {
        setIsProcessing(true);  // Disable the button and show loader
      
        let isStockAvailable = true;
        const updatedCart = [];

        for (const item of cart) {
            if (!item.id || typeof item.quantity === 'undefined' || typeof item.stock === 'undefined') {
                console.error('Invalid item details:', item);
                continue;  // Skip invalid items
            }

            const newStock = await updateProductStock(item.id, item.quantity);
            if (newStock < 0) {
                isStockAvailable = false;
                updatedCart.push({ ...item, outOfStock: true });
            } else {
                updatedCart.push({ ...item, outOfStock: false });
            }
        }

        if (isStockAvailable) {
            const currentOrderCount = await getOrderCount();
            const newOrderNumber = currentOrderCount + 1;

            const order = {
                userId: auth.currentUser ? auth.currentUser.uid : 'guest',
                email: recipientEmail,
                items: updatedCart,
                total: updatedCart.reduce((acc, item) => (item.outOfStock ? acc : acc + item.price * item.quantity), 0) + deliveryPrice,
                shippingMethod,
                contactInfo,
                shippingInfo: shippingMethod === 'delivery' ? shippingInfo : null,
                deliveryPrice,
                paymentMethod,
                date: new Date().toISOString(),
                orderNumber: newOrderNumber,
                deliveryDate, // Storing the delivery date
                deliveryTime: `${deliveryHour}:${deliveryMinute} ${deliveryTimeAMPM}`, // Storing the delivery time in 12-hour format
                additionalComments, // Storing any additional comments
            };

            await saveOrder(order);
            await updateOrderCount(newOrderNumber);

            // Save account info if the checkbox is checked and user is logged in
            if (auth.currentUser && saveAccountInfo) {
                const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
                await updateDoc(userDocRef, {
                    name: contactInfo.recipientName,
                    lastName: contactInfo.recipientLastName,
                    telefono: contactInfo.telefono,
                });
            }

            // Save delivery info if the checkbox is checked and user is logged in
            if (auth.currentUser && saveDeliveryInfo) {
                const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
                await updateDoc(userDocRef, {
                    direccion: shippingInfo.direccion,
                    provincia: shippingInfo.provincia,
                    canton: shippingInfo.canton,
                    distrito: shippingInfo.distrito,
                    codigoPostal: shippingInfo.codigoPostal,
                });
            }

            // Clear the cart from Firebase and local storage
            setCart([]);  // Clear the cart in the app state
            localStorage.removeItem('cart');  // Clear the cart from local storage

            if (auth.currentUser) {
                await saveCart([]);  // Clear the cart in Firebase
            }

            setOrderID(newOrderNumber);
            setIsPaymentProcessed(true);
        } else {
            Swal.fire('Error', 'Algunos artículos están agotados. Revisa tu carrito.', 'error');
        }
    } catch (err) {
        console.error('Failed to update stock:', err);
    } finally {
        setIsProcessing(false);  // Re-enable the button after processing
    }
  };

  const handleCloseModal = async () => {
    setIsPaymentProcessed(false);
    navigate('/order-history');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className={styles.checkoutContainer}>
      <h2 className={styles.title}>Checkout</h2>
  
      {/* Contact Information Section */}
      <div className={`${styles.formGroup} ${styles.contactInfo}`}>
        <h3 className={styles.sectionTitle}>Información de Contacto</h3>


        <div className={styles.inputContainer}>
          <i className={`fa fa-envelope ${styles.icon}`}></i>
          <input
            type="email"
            value={recipientEmail}
            onChange={handleEmailChange}
            placeholder="Email"
            required
            className={styles.formControl}
          />
        </div>
        
        <div className={styles.inputContainer}>
          <i className={`fa fa-user ${styles.icon}`}></i>
          <input
            type="text"
            name="recipientName"
            value={contactInfo.recipientName}
            onChange={handleContactInputChange}
            placeholder="Nombre"
            required
            className={styles.formControl}
            pattern="[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+" // Allow Spanish letters and accents
          />
        </div>
        <div className={styles.inputContainer}>
          <i className={`fa fa-user ${styles.icon}`}></i>
          <input
            type="text"
            name="recipientLastName"
            value={contactInfo.recipientLastName}
            onChange={handleContactInputChange}
            placeholder="Apellidos"
            required
            className={styles.formControl}
            pattern="[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+" // Allow Spanish letters and accents
          />
        </div>
        <div className={styles.inputContainer}>
          <i className={`fa fa-phone ${styles.icon}`}></i>
          <input
            type="text"
            name="telefono"
            value={contactInfo.telefono}
            onChange={handleContactInputChange}
            placeholder="Teléfono"
            required
            className={styles.formControl}
          />
        </div>
      </div>
  
      {/* Conditional checkboxes based on user login */}
      {auth.currentUser && (
        <div className={styles.checkboxGroup}>
          <input
            type="checkbox"
            className="form-check-input"
            id="saveAccountInfo"
            checked={saveAccountInfo}
            onChange={(e) => setSaveAccountInfo(e.target.checked)}
          />
          <label htmlFor="saveAccountInfo">Guardar información de contacto para futuras compras</label>
        </div>
      )}
  
      {/* Delivery Date Input with adjusted width */}
      <div className={`${styles.formGroup} ${styles.deliverySection}`}>
        <h4 className={styles.sectionTitle}>Día de Entrega</h4>
        <input
          type="date"
          name="deliveryDate"
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
          min={new Date().toISOString().split("T")[0]}
          className={`${styles.formControl} ${styles.shortInput}`}
          required
        />
      </div>
  
      {/* Delivery Time Input */}
      <div className={`${styles.formGroup} ${styles.deliverySection}`}>
        <h4 className={styles.sectionTitle}>Hora de Entrega</h4>
        <div className={styles.timePicker}>
          <select
            name="deliveryHour"
            value={deliveryHour}
            onChange={(e) => setDeliveryHour(e.target.value)}
            className={styles.formControl}
            required
          >
            <option value="" disabled>Selecciona Hora</option>
            {[...Array(12).keys()].map(i => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
  
          <select
            name="deliveryMinute"
            value={deliveryMinute}
            onChange={(e) => setDeliveryMinute(e.target.value)}
            className={styles.formControl}
            required
          >
            <option value="" disabled>Selecciona Minutos</option>
            {[0, 15, 30, 45].map(min => (
              <option key={min} value={min}>{min.toString().padStart(2, '0')}</option>
            ))}
          </select>
  
          <select
            name="deliveryTimeAMPM"
            value={deliveryTimeAMPM}
            onChange={(e) => setDeliveryTimeAMPM(e.target.value)}
            className={styles.formControl}
            required
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
  
      {/* Additional Comments */}
      <div className={styles.formGroup}>
        <h4 className={styles.sectionTitle}>Comentarios Adicionales (Opcional)</h4>
        <textarea
          name="additionalComments"
          value={additionalComments}
          onChange={(e) => setAdditionalComments(e.target.value)}
          placeholder="Especifica instrucciones especiales para tu pedido o entrega"
          className={styles.formControl}
        />
      </div>
  
      {/* Shipping Method Section */}
      <div className={`${styles.formGroup} ${styles.shippingSection}`}>
        <h3 className={styles.sectionTitle}>Método de Entrega - Recolección</h3>
        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="shippingMethod"
              id="pickup"
              value="pickup"
              checked={shippingMethod === 'pickup'}
              onChange={(e) => setShippingMethod(e.target.value)}
            />
            Recoger en el Local
          </label>
          <label>
            <input
              type="radio"
              name="shippingMethod"
              id="delivery"
              value="delivery"
              checked={shippingMethod === 'delivery'}
              onChange={(e) => setShippingMethod(e.target.value)}
            />
            Envío
          </label>
        </div>
      </div>
  
      {/* Store Address for Pickup */}
      {shippingMethod === 'pickup' && (
        <div className={styles.pickupInfo}>
          <p><strong>Estamos en:</strong> Plaza Vistana Oeste</p>
          <p><strong>Dirección:</strong> Lindora, entre a TacoBell y Soda Tapia San José Pozos</p>
        </div>
      )}
  
{/* Delivery Information Section */}
{shippingMethod === 'delivery' && (
    <div className={styles.formGroup}>
        <h4 className={styles.sectionTitle}>Información de Entrega</h4>
        <input
            type="text"
            name="direccion"
            value={shippingInfo.direccion}
            onChange={handleShippingInputChange}
            placeholder="Dirección Completa"
            required
            className={styles.formControl}
        />
        <div className={styles.addressFields}>
            <label>Provincia</label>
            <select
                name="provincia"
                value={shippingInfo.provincia}
                onChange={handleShippingInputChange}
                className={styles.formControl}
            >
                {Object.keys(provinces).map((prov) => (
                    <option key={prov} value={prov}>{prov}</option>
                ))}
            </select>

            <label>Cantón</label>
            <select
                name="canton"
                value={shippingInfo.canton}
                onChange={handleShippingInputChange}
                className={styles.formControl}
            >
                {shippingInfo.provincia && Object.keys(provinces[shippingInfo.provincia]?.cantones || {}).map((canton) => (
                    <option key={canton} value={canton}>{canton}</option>
                ))}
            </select>

            <label>Distrito</label>
            <select
                name="distrito"
                value={shippingInfo.distrito}
                onChange={handleShippingInputChange}
                className={styles.formControl}
            >
                {shippingInfo.canton && provinces[shippingInfo.provincia]?.cantones[shippingInfo.canton]?.map((distrito) => (
                    <option key={distrito} value={distrito}>{distrito}</option>
                ))}
            </select>

            {/* Postal Code - Optional */}
            <label>Código Postal (Opcional)</label>
            <input
                type="text"
                name="codigoPostal"
                value={shippingInfo.codigoPostal}
                onChange={handleShippingInputChange}
                placeholder="Código Postal"
                className={styles.formControl}
                pattern={postalCodePattern.source}
            />
        </div>

        {/* Save Delivery Info Checkbox, visible only for "Envío" */}
        {auth.currentUser && (
            <div className={styles.checkboxGroup}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="saveDeliveryInfo"
                    checked={saveDeliveryInfo}
                    onChange={(e) => setSaveDeliveryInfo(e.target.checked)}
                />
                <label htmlFor="saveDeliveryInfo">Guardar la información de entrega para futuras compras</label>
            </div>
        )}
    </div>
)}

  
      {/* Payment Method Section */}
      <div className={`${styles.formGroup} ${styles.paymentSection}`}>
        <h3 className={styles.sectionTitle}>Método de Pago</h3>
        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              id="sinpe"
              value="SINPE Movil"
              checked={paymentMethod === 'SINPE Movil'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            SINPE Movil
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              id="transferencia"
              value="Transferencia Bancaria"
              checked={paymentMethod === 'Transferencia Bancaria'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            Transferencia Bancaria
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              id="local"
              value="Pagar en el Local"
              checked={paymentMethod === 'Pagar en el Local'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            Pagar en el Local
          </label>
        </div>
  
        {/* Payment Instructions */}
        {paymentMethod === 'SINPE Movil' && (
          <div className={styles.paymentInstructions}>
            <p><strong>Por favor hacer el pago al SINPE 64037952 a nombre de Armony Floristería.</strong></p>
            <p>En el detalle, por favor incluir el número de orden y tu nombre.</p>
          </div>
        )}
  
        {paymentMethod === 'Transferencia Bancaria' && (
          <div className={styles.paymentInstructions}>
            <p><strong>Por favor hacer el pago en una de las siguientes cuentas:</strong></p>
            <ul>
              <li>BAC IBAN: CR610102000094400831775</li>
              <li>BN IBAN: CR6101423400009440083179</li>
              <li>BCR IBAN: CR610142340000944008317</li>
            </ul>
            <p>En el detalle, por favor incluir el número de orden y tu nombre.</p>
          </div>
        )}
  
        {paymentMethod === 'Pagar en el Local' && (
          <div className={styles.paymentInstructions}>
            <p><strong>Puedes pagar con cualquier tipo de tarjeta de crédito o débito, en efectivo, SINPE y Transferencia Bancaria.</strong></p>
          </div>
        )}
      </div>
  
      {/* Order Summary Section */}
      <div className={`${styles.formGroup} ${styles.orderSummarySection}`}>
        <h3 className={styles.sectionTitle}>Resumen de tu Orden</h3>
        <ul className={styles.orderSummaryList}>
          {cart.map((item, index) => (
            <li key={index} className={styles.orderItem}>
              {item.name} - {formatCurrency(item.price)} x {item.quantity}
              {item.option && <small>Opción: {item.option.name}</small>}
            </li>
          ))}
        </ul>
        {shippingMethod === 'delivery' && (
          <h4 className={styles.totalTitle}>Envío: {formatCurrency(deliveryPrice)}</h4>
        )}
        <h4 className={styles.totalTitle}>
          Total: {formatCurrency(cart.reduce((acc, item) => acc + item.price * item.quantity, 0) + (shippingMethod === 'delivery' ? deliveryPrice : 0))}
        </h4>
      </div>
  
      {/* Proceed to Payment Button */}
      <button onClick={handleCheckout} className={styles.checkoutBtn} disabled={isProcessing}>
        {isProcessing ? (
          <>
            <span className={styles.spinnerSmall}></span> Procesando...
          </>
        ) : (
          'Proceder al Pago'
        )}
      </button>
  
      {/* Loading Overlay (shown when processing) */}
      {isProcessing && (
        <div className={styles.loadingOverlay}>
          <div className={styles.spinner}></div>
        </div>
      )}
  
      {/* Modal for Order Confirmation */}
      {isPaymentProcessed && (
        <Modal
          isOpen={isPaymentProcessed}
          onRequestClose={handleCloseModal}
          contentLabel="Confirmación de Orden"
          className={styles.modalContent}
          overlayClassName={styles.modalOverlay}
        >
          <h2>Pedido Realizado con Éxito!</h2>
          {orderID && (
            <>
              <p>Este es tu número de Orden:</p>
              <p><strong>{orderID}</strong></p>
            </>
          )}
          <p>Recuerda por favor incluirlo en el detalle del pago junto a tu nombre.</p>
          <button onClick={handleCloseModal} className="btn btn-primary">Cerrar</button>
        </Modal>
      )}
    </div>
  );
  
  

};

export default Checkout;
