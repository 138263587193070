import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import styles from './CustomerProfile.module.css'; // Import the CSS module
import provinces from '../data/provinces.json'; // Import the provinces JSON for dropdown

const CustomerProfile = () => {
  const [user, setUser] = useState(null);
  const [profileInfo, setProfileInfo] = useState({
    email: '',
    name: '',
    lastName: '',
    telefono: '', // Move phone number here to Account Information
  });

  const [shippingInfo, setShippingInfo] = useState({
    recipientEmail: '',
    recipientName: '',
    recipientLastName: '',
    direccion: '',
    provincia: 'San José', // Default to San José
    canton: '',
    distrito: '',
    codigoPostal: '',
    pais: 'Costa Rica',
  });

  const navigate = useNavigate();

  // Validation patterns
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const namePattern = /^[a-zA-Z\s]+$/;
  const phonePattern = /^[0-9]{8}$/; // Costa Rican phone number (8 digits)
  const postalCodePattern = /^[0-9]+$/;

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setProfileInfo({
            email: currentUser.email || '',
            name: data.name || '',
            lastName: data.lastName || '',
            telefono: data.telefono || '', // Set the phone number here
          });
          setShippingInfo({
            recipientEmail: currentUser.email || '',
            recipientName: data.name || '',
            recipientLastName: data.lastName || '',
            direccion: data.direccion || '',
            provincia: data.provincia || 'San José',
            canton: data.canton || '',
            distrito: data.distrito || '',
            codigoPostal: data.codigoPostal || '',
            pais: 'Costa Rica',
          });
        }
      }
    };

    fetchUser();
  }, []);

  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));

    // Reset canton and distrito when province changes
    if (name === 'provincia') {
      setShippingInfo((prevInfo) => ({ ...prevInfo, canton: '', distrito: '' }));
    }
  };

  const validateFields = () => {
    if (!emailPattern.test(profileInfo.email)) {
      alert('Please enter a valid email address.');
      return false;
    }
    if (!namePattern.test(profileInfo.name)) {
      alert('Please enter a valid first name.');
      return false;
    }
    if (!namePattern.test(profileInfo.lastName)) {
      alert('Please enter a valid last name.');
      return false;
    }
    if (!phonePattern.test(profileInfo.telefono)) {
      alert('Please enter a valid 8-digit phone number.');
      return false;
    }
    if (!shippingInfo.direccion || !postalCodePattern.test(shippingInfo.codigoPostal)) {
      alert('Please enter a valid address and postal code.');
      return false;
    }
    return true;
  };

  const handleSaveProfile = async () => {
    if (!validateFields()) return;

    if (user) {
      const userDocRef = doc(firestore, 'users', user.uid);
      await updateDoc(userDocRef, { ...profileInfo, ...shippingInfo });
      alert('Profile updated successfully.');
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (err) {
      console.error('Failed to sign out:', err);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Perfil de Usuario</h2>
      {user ? (
        <div className={styles.profileContainer}>
          {/* Account Information Section */}
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>Información de la Cuenta</h3>
            <form>
              <div className={styles.formGroup}>
                <label>Correo</label>
                <input
                  type="email"
                  name="email"
                  value={profileInfo.email}
                  className={styles.input}
                  readOnly
                />
              </div>
              <div className={styles.formGroup}>
                <label>Nombre</label>
                <input
                  type="text"
                  name="name"
                  value={profileInfo.name}
                  onChange={handleProfileInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Apellido</label>
                <input
                  type="text"
                  name="lastName"
                  value={profileInfo.lastName}
                  onChange={handleProfileInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Teléfono</label> {/* Moved Phone to Account Information */}
                <input
                  type="text"
                  name="telefono"
                  value={profileInfo.telefono}
                  onChange={handleProfileInputChange}
                  className={styles.input}
                  required
                />
              </div>
            </form>
          </div>

          {/* Shipping Information Section */}
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>Información de Envío</h3>
            <form>
              <div className={styles.formGroup}>
                <label>Correo</label>
                <input
                  type="email"
                  name="recipientEmail"
                  value={shippingInfo.recipientEmail}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Nombre</label>
                <input
                  type="text"
                  name="recipientName"
                  value={shippingInfo.recipientName}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Apellidos</label>
                <input
                  type="text"
                  name="recipientLastName"
                  value={shippingInfo.recipientLastName}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Dirección</label>
                <input
                  type="text"
                  name="direccion"
                  value={shippingInfo.direccion}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Provincia</label>
                <select
                  name="provincia"
                  value={shippingInfo.provincia}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                >
                  {Object.keys(provinces).map((prov) => (
                    <option key={prov} value={prov}>{prov}</option>
                  ))}
                </select>
              </div>
              <div className={styles.formGroup}>
                <label>Cantón</label>
                <select
                  name="canton"
                  value={shippingInfo.canton}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                >
                  {shippingInfo.provincia && Object.keys(provinces[shippingInfo.provincia]?.cantones || {}).map((canton) => (
                    <option key={canton} value={canton}>{canton}</option>
                  ))}
                </select>
              </div>
              <div className={styles.formGroup}>
                <label>Distrito</label>
                <select
                  name="distrito"
                  value={shippingInfo.distrito}
                  onChange={handleShippingInputChange}
                  className={styles.input}
                  required
                >
                  {shippingInfo.canton && provinces[shippingInfo.provincia]?.cantones[shippingInfo.canton]?.map((distrito) => (
                    <option key={distrito} value={distrito}>{distrito}</option>
                  ))}
                </select>
              </div>
              <div className={styles.formGroup}>
                <label>Código Postal</label>
                <input
                  type="text"
                  name="codigoPostal"
                  value={shippingInfo.codigoPostal}
                  onChange={handleShippingInputChange}
                  className={styles.input}
               
                />
              </div>
              <div className={styles.formGroup}>
                <label>País</label>
                <input
                  type="text"
                  name="pais"
                  value={shippingInfo.pais}
                  className={styles.input}
                  readOnly
                />
              </div>
              <button
                type="button"
                onClick={handleSaveProfile}
                className={styles.saveButton}
              >
                Guardar Perfil
              </button>
            </form>
          </div>

          <button onClick={handleSignOut} className={styles.signOutButton}>
            Salir de la Cuenta
          </button>
          <Link to="/order-history" className={styles.orderHistoryButton}>
            Ver Historial de Ordenes
          </Link>
        </div>
      ) : (
        <p>No user is logged in.</p>
      )}
    </div>
  );
};

export default CustomerProfile;
