import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { auth } from '../firebase';
import Modal from 'react-modal';
import styles from './CartSidebar.module.css'; // Import CSS module

const CartSidebar = ({ isOpen, toggleCartSidebar }) => {
  const { cart, updateQuantity, removeFromCart } = useCart();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleProceedToCheckout = () => {
    if (!auth.currentUser) {
      // Open the modal if the user is not logged in
      setModalOpen(true);
    } else {
      // Close the cart sidebar and proceed to checkout if the user is logged in
      toggleCartSidebar();
      navigate('/checkout');
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleContinueAsGuest = () => {
    // Close the modal, cart sidebar, and proceed to checkout as a guest
    setModalOpen(false);
    toggleCartSidebar(); // Close the cart
    navigate('/checkout');
  };

  const handleLoginRedirect = () => {
    // Close the modal and cart sidebar, then redirect to the login page
    setModalOpen(false);
    toggleCartSidebar(); // Close the cart
    navigate('/customer/login');
  };

  const handleRegisterRedirect = () => {
    // Close the modal and cart sidebar, then redirect to the register page
    setModalOpen(false);
    toggleCartSidebar(); // Close the cart
    navigate('/customer/register');
  };


  const handleQuantityChange = (id, optionName, newQuantity) => {
    updateQuantity(id, newQuantity, optionName);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
    }).format(value);
  };

  const totalAmount = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <div className={`${styles.cartSidebar} ${isOpen ? styles.open : ''}`}>
      <div className={styles.cartHeader}>
        <h3>Mi Carrito</h3>
        <button onClick={toggleCartSidebar} className={styles.closeButton}>
          &times;
        </button>
      </div>

      <ul className={styles.cartItemList}>
        {cart.map((item, index) => (
          <li key={index} className={`${styles.cartItem} ${styles.cartItemAnimation}`}>
            <img src={item.principalImageUrl} alt={item.name} className={styles.cartItemImage} />
            <div className={styles.cartItemDetails}>
              <p className={styles.itemName}>{item.name}</p>
              {item.option && <p className={styles.itemOption}>Option: {item.option.name}</p>}
              <p className={styles.itemPrice}>{formatCurrency(item.price)}</p>
              <div className={styles.quantityControls}>
                <button
                  onClick={() => handleQuantityChange(item.id, item.option?.name, item.quantity - 1)}
                  className={styles.quantityButton}
                >
                  -
                </button>
                <input
                  type="number"
                  value={item.quantity}
                  min="1"
                  max={item.stock}
                  onChange={(e) => handleQuantityChange(item.id, item.option?.name, parseInt(e.target.value, 10))}
                  className={styles.quantityInput}
                />
                <button
                  onClick={() => handleQuantityChange(item.id, item.option?.name, item.quantity + 1)}
                  className={styles.quantityButton}
                >
                  +
                </button>
              </div>
              <button
                onClick={() => removeFromCart(item.id, item.option?.name)}
                className={styles.removeButton}
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>

      {cart.length > 0 && (
        <div className={styles.cartFooter}>
          <div className={styles.cartTotal}>
            <span>Total:</span>
            <span>{formatCurrency(totalAmount)}</span>
          </div>
          <button onClick={handleProceedToCheckout} className={styles.checkoutButton}>
            Proceder a la Compra
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Checkout Options"
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <h2>Opciones de Checkout</h2>
        <p>Por favor, elije cómo deseas proceder:</p>
        <div className={styles.modalButtons}>
          <button onClick={handleContinueAsGuest} className={styles.modalButton}>
            Seguir como Invitado
          </button>
          <button onClick={handleLoginRedirect} className={styles.modalButton}>
            Login
          </button>
          <button onClick={handleRegisterRedirect} className={styles.modalButton}>
            Registrarse
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CartSidebar;
