import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getProducts, getCategories } from '../services/firestoreService';
import { useCart } from '../contexts/CartContext';
import styles from './ProductCatalog.module.css'; // Import CSS module
import { useSpring, animated } from '@react-spring/web'; // Import React Spring
import flowerIcon from '../images/flower.ico';
import Swal from 'sweetalert2';
import Modal from 'react-modal';

const ProductCatalog = ({ toggleCartSidebar }) => {

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [sort, setSort] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [modalProduct, setModalProduct] = useState(null); // For handling product options modal
  const [tarjetaModalProduct, setTarjetaModalProduct] = useState(null); // For handling tarjeta message modal
  const [isAnimating, setIsAnimating] = useState(false); // Track animation state
  const [animationPosition, setAnimationPosition] = useState({}); // Store animation start position
  const [isAnimatingFlowerInNavBar, setIsAnimatingFlowerInNavBar] = useState(false); // Track navbar flower animation
  const { addToCart } = useCart();
  const location = useLocation();
  const [tarjetaMessage, setTarjetaMessage] = useState(''); // State for "Tarjeta" message

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const search = query.get('search');
    if (search) {
      setSearchTerm(search);
    }

    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getProducts();
        const productsList = productsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(product => !product.disabled); // Exclude disabled products
        setProducts(productsList);
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getCategories();
        const categoriesList = categoriesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(category => !category.disabled); // Exclude disabled categories
        setCategories(categoriesList);
      } catch (err) {
        console.error('Failed to fetch categories:', err);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [location.search]);

  const handleSortChange = (e) => {
    const { value } = e.target;
    setSort(value);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setCategory(value);
  };

  const startAnimation = (productElement) => {
    const cartIcon = document.getElementById('cart-icon'); // Get cart icon

    if (cartIcon && productElement) {
      const productPosition = productElement.getBoundingClientRect();
      const cartPosition = cartIcon.getBoundingClientRect();

      // Calculate position relative to document (considering scroll)
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollX = window.scrollX || window.pageXOffset;

      setAnimationPosition({
        fromX: productPosition.x + scrollX,
        fromY: productPosition.y + scrollY,
        toX: cartPosition.x + scrollX,
        toY: cartPosition.y + scrollX,
      });

      setIsAnimating(true);
      setIsAnimatingFlowerInNavBar(true); // Trigger navbar flower animation
      setTimeout(() => {
        setIsAnimating(false);
        setIsAnimatingFlowerInNavBar(false); // Stop the animation in the navbar after the transition
      }, 1000); // Duration of the animation
    }
  };

  const handleOptionSelect = (productId, option) => {
    setSelectedOption({ ...selectedOption, [productId]: option });
  };

  const handleAddToCart = (product, event) => {
    const productElement = event.currentTarget.closest('.card');
    const option = selectedOption[product.id];
    const quantity = 1;
  
    if (product.options && product.options.length > 0 && (!option || option === "")) {
      setModalProduct(product);
      return;
    }
  
    if (product.hasTarjeta) {
      setTarjetaModalProduct(product);
      return;
    }
  
    const selectedProduct = {
      ...product,
      price: option ? option.price : product.price,
      option: option || null,
      tarjetaMessage: null,
      quantity,
    };
  
    startAnimation(productElement);
    addToCart(selectedProduct, toggleCartSidebar); // Open the cart sidebar
  };
  


  const handleModalAddToCart = () => {
    const product = modalProduct;
    const option = selectedOption[product.id];
  
    if (product.options && product.options.length > 0 && (!option || option === "")) {
      Swal.fire('Selecciona una Opción', 'Por favor selecciona una opción válida.', 'warning');

      return;
    }
  
    if (product.hasTarjeta) {
      setTarjetaModalProduct(product);
      setModalProduct(null); // Close options modal, open tarjeta modal
    } else {
      const selectedProduct = {
        ...product,
        price: option ? option.price : product.price,
        option,
        tarjetaMessage: null,
        quantity: 1,
      };
  
      startAnimation(document.getElementById(`product-${product.id}`)); // Animate product to cart
      addToCart(selectedProduct, toggleCartSidebar); // Open the cart sidebar after adding the product
      setModalProduct(null); // Close the modal after adding to cart
    }
  };
  

  const handleTarjetaSave = () => {
    const product = tarjetaModalProduct;
  
    const selectedProduct = {
      ...product,
      price: product.price,
      option: selectedOption[product.id] || null, // Use selected option if any
      tarjetaMessage, // Include the tarjeta message
      quantity: 1,
    };
  
    startAnimation(document.getElementById(`product-${product.id}`)); // Animate product to cart
    addToCart(selectedProduct, toggleCartSidebar); // Open the cart sidebar after adding the product
    setTarjetaModalProduct(null); // Close tarjeta modal after saving
    setTarjetaMessage(''); // Reset tarjeta message
  };
  

  const handleTarjetaSkip = () => {
    const product = tarjetaModalProduct;

    const selectedProduct = {
      ...product,
      price: product.price,
      option: selectedOption[product.id] || null, // Use selected option if any
      tarjetaMessage: null, // Skip the tarjeta message
      quantity: 1, // Set default quantity to 1
    };

    startAnimation(document.getElementById(`product-${product.id}`)); // Animate product to cart
    addToCart(selectedProduct, toggleCartSidebar);
    setTarjetaModalProduct(null); // Close tarjeta modal after skipping
    setTarjetaMessage(''); // Reset tarjeta message
  };

  const filteredProducts = products
  .filter(product => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (product.shortDescription && product.shortDescription.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (product.fullDescription && product.fullDescription.toLowerCase().includes(searchTerm.toLowerCase()))
  )
  .filter(product => (category ? product.category === category : true))
  .sort((a, b) => {
    if (sort === 'price-asc') return a.price - b.price;
    if (sort === 'price-desc') return b.price - a.price;
    if (sort === 'popularity') return b.popularity - a.popularity;
    return 0;
  });


  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Animation for search bar, category, and sort selects
  const searchBarAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  });

  const flowerAnimation = useSpring({
    from: { left: `${animationPosition.fromX}px`, top: `${animationPosition.fromY}px`, opacity: 1 },
    to: { left: `${animationPosition.toX}px`, top: `${animationPosition.toY}px`, opacity: 0 },
    config: { duration: 1000 },
    reset: isAnimating,
    onRest: () => setIsAnimating(false),
  });

  return (
    <div className={`${styles.container} ${styles.catalogContainer}`}>
      <h2 className="my-4">Nuestros Productos</h2>
      <animated.div style={searchBarAnimation} className={styles.searchContainer}>
        <input
          type="text"
          className={`form-control ${styles.searchBar}`}
          placeholder="Buscar productos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select className={`form-control mt-2 ${styles.categorySelect}`} value={category} onChange={handleCategoryChange}>
          <option value="">Todas las Categorías</option>
          {categories.map(cat => (
            <option key={cat.id} value={cat.name}>{cat.name}</option>
          ))}
        </select>
        <select className={`form-control mt-2 ${styles.sortSelect}`} value={sort} onChange={handleSortChange}>
          <option value="">Filtros</option>
          <option value="price-asc">Precio: Menor a Mayor</option>
          <option value="price-desc">Precio: Mayor a Menor</option>
          <option value="popularity">Popularidad</option>
        </select>
      </animated.div>

      <div className="row">
  {filteredProducts.map(product => {
    const selectedPrice = selectedOption[product.id]?.price || product.price; // Update price based on selected option
    return (
      <div key={product.id} className="col-md-4 d-flex" id={`product-${product.id}`}>
        <div className={`card mb-4 ${styles.card}`}>
          <div className={styles['card-img-container']}>
            <img src={product.principalImageUrl} alt={product.name} className={`card-img-top ${styles.cardImgTop}`} />
          </div>
          <div className={`card-body ${styles.cardBody}`}>
            <h5 className={`card-title ${styles.cardTitle}`}>{product.name}</h5>
            <p className={`card-text ${styles.cardText}`}>
              {product.shortDescription} {/* Show short description on product card */}
            </p>
            <p className={`card-text ${styles.productPrice}`}>
              <strong>{formatCurrency(selectedPrice)}</strong> {/* Display dynamic price */}
            </p>
            <p className={`card-text ${styles.productCategory}`}>Categoría: {product.category}</p>
            <p className={`card-text ${styles.productStock}`}>
              Stock: {product.stock > 0 ? product.stock : 'Agotado'}
            </p>

            {product.options && product.options.length > 0 && (
              <select
                onChange={(e) => handleOptionSelect(product.id, JSON.parse(e.target.value))}
                className={`form-control mt-2 ${styles.optionSelect}`}
              >
                <option value="" disabled selected>Selecciona una Opción</option> {/* Disable placeholder */}
                {product.options.map((opt, index) => (
                  <option key={index} value={JSON.stringify(opt)}>
                    {opt.name} - {formatCurrency(opt.price)}
                  </option>
                ))}
              </select>
            )}

            <button 
              onClick={(e) => handleAddToCart(product, e)} 
              className={`btn btn-primary ${styles.productButton}`} 
              disabled={product.stock <= 0}
            >
              {product.stock > 0 ? 'Agregar al Carrito' : 'Agotado'}
            </button>

            <Link to={`/products/${product.id}`}>
              <button className={`btn btn-secondary mt-2 ${styles.productButton}`}>Ver Producto</button>
            </Link>
          </div>
        </div>
      </div>
    );
  })}
</div>

      {isAnimating && (
        <animated.img src={flowerIcon} style={flowerAnimation} className={styles.flowerAnimation} />
      )}
  
      {/* Modal for product options */}
      <Modal
        isOpen={!!modalProduct}
        onRequestClose={() => setModalProduct(null)}
        contentLabel="Selecciona una Opción"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '450px', // Small width
            height: 'auto',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darker overlay for focus
          },
        }}
      >
        {modalProduct && (
          <div>
            <h4>Select an option for {modalProduct.name}</h4>
            <select
              onChange={(e) => handleOptionSelect(modalProduct.id, JSON.parse(e.target.value))}
              className={`form-control mt-2 ${styles.optionSelect}`}
            >
              <option value="" disabled selected>Selecciona una Opción</option> {/* Disable placeholder */}
              {modalProduct.options.map((opt, index) => (
                <option key={index} value={JSON.stringify(opt)}>
                  {opt.name} - {formatCurrency(opt.price)}
                </option>
              ))}
            </select>
            <button className="btn btn-primary mt-3" onClick={handleModalAddToCart}>
              Agregar al Carrito
            </button>
          </div>
        )}
      </Modal>
  
      {/* Modal for "Tarjeta" message */}
      <Modal
        isOpen={!!tarjetaModalProduct}
        onRequestClose={() => setTarjetaModalProduct(null)}
        contentLabel="Enter Tarjeta Message"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '450px',
            height: 'auto',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darker overlay for focus
          },
        }}
      >
        {tarjetaModalProduct && (
          <div>
            <h4>Escribe tu mensaje para la tarjetita</h4>
            <input
              type="text"
              id={`tarjeta-modal-message`}
              className="form-control"
              placeholder="Tu mensaje..."
              value={tarjetaMessage}
              onChange={(e) => setTarjetaMessage(e.target.value)}
            />
            <div className="mt-3 d-flex justify-content-between">
              <button className="btn btn-primary" onClick={handleTarjetaSave}>
                Guardar Mensaje
              </button>
              <button className="btn btn-secondary" onClick={handleTarjetaSkip}>
                Saltar
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
  
  
};

export default ProductCatalog;
