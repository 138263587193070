import { firestore, storage, auth } from '../firebase';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, setDoc, getDoc, query, where,onSnapshot, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";


const productsCollection = collection(firestore, 'products');
const usersCollection = collection(firestore, 'users');
const ordersCollection = collection(firestore, 'orders');
const adminsCollection = collection(firestore, 'admins');
const categoriesCollection = collection(firestore, 'categories');
const homepageContentCollection = collection(firestore, 'homepageContent');

export const getReviewsForAllProducts = async (firestore) => {
  const productsSnapshot = await getDocs(collection(firestore, 'products'));
  const allReviews = [];

  for (let productDoc of productsSnapshot.docs) {
    const reviewsSnapshot = await getDocs(collection(firestore, 'products', productDoc.id, 'reviews'));
    reviewsSnapshot.docs.forEach((reviewDoc) => {
      allReviews.push(reviewDoc.data());
    });
  }

  return allReviews;
};

// Function to get the current order count
export const getOrderCount = async () => {
  const countDoc = await getDoc(doc(firestore, 'config', 'orderCount'));
  if (countDoc.exists()) {
    return countDoc.data().currentOrderNumber;
  } else {
    // If the order count document doesn't exist, initialize it with 0
    await setDoc(doc(firestore, 'config', 'orderCount'), { currentOrderNumber: 0 });
    return 0;
  }
};

// Function to update the order count
export const updateOrderCount = async (newCount) => {
  await updateDoc(doc(firestore, 'config', 'orderCount'), {
    currentOrderNumber: newCount
  });
};

export const saveOrder = async (order) => {
  // Get the current order count
  const currentOrderCount = await getOrderCount();

  // Increment the order count
  const newOrderNumber = currentOrderCount + 1;

  // Attach the newOrderNumber to the order object and set default statuses
  const orderWithStatuses = {
    ...order,
    orderNumber: newOrderNumber,
    status: 'Pendiente',       // Set default status to 'Pendiente'
    paymentStatus: 'Pago Pendiente', // Set default payment status to 'Pago Pendiente'
  };

  // Save the order with the statuses and order number
  const orderDoc = await addDoc(ordersCollection, orderWithStatuses);

  // Update order count in Firestore
  await updateOrderCount(newOrderNumber);

  // Update order count for each product in the order
  for (let item of order.items) {
    const productDoc = doc(firestore, 'products', item.id);
    const productSnapshot = await getDoc(productDoc);
    if (productSnapshot.exists()) {
      const currentOrderCount = productSnapshot.data().orderCount || 0;
      await updateDoc(productDoc, {
        orderCount: currentOrderCount + item.quantity
      });
    }
  }

  return orderDoc;
};



// Other functions related to products, users, cart, etc.
export const addProduct = async (product, imageFile) => {
  let imageUrl = '';
  if (imageFile) {
    const storageRef = ref(storage, `products/${imageFile.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(snapshot.ref);
    } catch (error) {
      throw error;
    }
  }
  const productWithMetadata = {
    ...product,
    imageUrl,
    createdAt: serverTimestamp(),
    orderCount: 0,
  };
  return await addDoc(productsCollection, productWithMetadata);
};

export const getProduct = async (id) => {
  const productDoc = doc(firestore, 'products', id);
  const productSnapshot = await getDoc(productDoc);
  if (productSnapshot.exists()) {
    return productSnapshot.data();
  } else {
    throw new Error('Product not found');
  }
};

export const getProducts = async () => {
  return await getDocs(productsCollection);
};

export const getProductsByCategory = async (categoryName) => {
  const q = query(productsCollection, where("category", "==", categoryName), where("disabled", "==", false));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const updateProduct = async (id, updatedProduct, imageFile) => {
  let imageUrl = updatedProduct.imageUrl;
  if (imageFile) {
    const storageRef = ref(storage, `products/${imageFile.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(snapshot.ref);
    } catch (error) {
      throw error;
    }
  }
  const productWithoutFile = { ...updatedProduct, imageUrl };
  delete productWithoutFile.imageFile;
  return await updateDoc(doc(firestore, 'products', id), productWithoutFile);
};

export const deleteProduct = async (id) => {
  return await deleteDoc(doc(firestore, 'products', id));
};

export const saveCart = async (cart) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(usersCollection, user.uid);
    await updateDoc(userDocRef, { cart });
  }
};

export const loadCart = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(usersCollection, user.uid));
    if (userDoc.exists()) {
      return userDoc.data().cart || [];
    }
  }
  return [];
};

export const updateProductStock = async (productId, quantity) => {
  const productDoc = doc(firestore, 'products', productId);
  const productSnapshot = await getDoc(productDoc);

  if (productSnapshot.exists()) {
    const currentStock = productSnapshot.data().stock;
    const newStock = currentStock - quantity;

    await updateDoc(productDoc, {
      stock: newStock
    });

    return newStock;
  } else {
    throw new Error('Product not found');
  }
};

export const saveWishlist = async (wishlist) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(usersCollection, user.uid);
    await updateDoc(userDocRef, { wishlist });
  }
};

export const loadWishlist = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(usersCollection, user.uid));
    if (userDoc.exists()) {
      return userDoc.data().wishlist || [];
    }
  }
  return [];
};

export const loadOrderHistory = (callback) => {
  const user = auth.currentUser;
  if (user) {
    const ordersQuery = query(collection(firestore, 'orders'), where('userId', '==', user.uid));
    return onSnapshot(ordersQuery, (snapshot) => {
      const orders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(orders);
    });
  }
  return () => {}; // Return a no-op function when there’s no user
};

// Function to get the order ID for a specific product and user
export const getOrderCountForProduct = async (productId, userId) => {
  const ordersQuery = query(ordersCollection, where('userId', '==', userId));
  const orderSnapshots = await getDocs(ordersQuery);

  for (let orderDoc of orderSnapshots.docs) {
    const order = orderDoc.data();
    const productInOrder = order.items.find(item => item.id === productId);

    if (productInOrder) {
      return order.orderNumber;  // Return the order number (ID) if the product is found in the order
    }
  }

  return null; // Return null if no order is found for the product and user
};


export const addReview = async (productId, review) => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(usersCollection, user.uid));
    if (userDoc.exists()) {
      const userName = userDoc.data().name;
      const reviewWithUser = { ...review, userName };

      // Check for the orderID related to this product and user
      const orderID = await getOrderCountForProduct(productId, user.uid);
      if (!orderID) {
        throw new Error('No valid order found for this product');
      }

      const reviewWithOrderID = { ...reviewWithUser, orderID };

      const reviewDoc = doc(collection(firestore, 'products', productId, 'reviews'), new Date().toISOString());
      return await setDoc(reviewDoc, reviewWithOrderID);
    }
  }
  throw new Error('User not authenticated or user data not found');
};


export const getReviews = async (productId) => {
  const reviewsSnapshot = await getDocs(collection(firestore, 'products', productId, 'reviews'));
  return reviewsSnapshot.docs.map(doc => doc.data());
};

export const addAdmin = async (email) => {
  return await addDoc(adminsCollection, { email });
};

export const isAdmin = async (email) => {
  const q = query(adminsCollection, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};

export const getAdmins = async () => {
  const adminsSnapshot = await getDocs(adminsCollection);
  return adminsSnapshot.docs.map(doc => doc.data());
};

export const updateCartItemQuantity = async (productId, quantity) => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const cart = userDoc.data().cart || [];
      const updatedCart = cart.map(item =>
        item.id === productId ? { ...item, quantity } : item
      );
      await updateDoc(userDocRef, { cart: updatedCart });
      return updatedCart;
    }
  }
  return [];
};

// New category-related functions
export const addCategory = async (categoryName) => {
  return await addDoc(categoriesCollection, { name: categoryName });
};

export const getCategories = async () => {
  return await getDocs(categoriesCollection);
};

export const deleteCategory = async (id) => {
  return await deleteDoc(doc(firestore, 'categories', id));
};

// Homepage content-related functions
export const getHomepageContent = async () => {
  return await getDocs(homepageContentCollection);
};

export const addHomepageContent = async (content) => {
  return await addDoc(homepageContentCollection, content);
};

export const updateHomepageContent = async (id, updatedContent) => {
  return await updateDoc(doc(firestore, 'homepageContent', id), updatedContent);
};

export const deleteHomepageContent = async (id) => {
  return await deleteDoc(doc(firestore, 'homepageContent', id));
};

export const setCurrentHomepageContent = async (id) => {
  return await setDoc(doc(firestore, 'homepageContent', 'current'), { activeContent: id });
};

export const getBestSellingProducts = async () => {
  const q = query(productsCollection, where("orderCount", ">", 0));
  const querySnapshot = await getDocs(q);
  const products = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  // Sort products by order count in descending order and return the top 5
  return products.sort((a, b) => b.orderCount - a.orderCount).slice(0, 5);
};

export const getRecentlyAddedProducts = async () => {
  const now = new Date();
  const lastMonth = new Date(now.setMonth(now.getMonth() - 1));

  const q = query(productsCollection, where("createdAt", ">=", lastMonth));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};
