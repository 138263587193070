import React, { useState } from 'react';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { saveCart } from '../services/firestoreService';
import styles from './CustomerRegister.module.css';  // Import the CSS module
import registerBackground from '../images/register3.jpg'; // Import the background image


const CustomerRegister = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    lastName: ''
  });
  const navigate = useNavigate();
  const { setCart } = useCart();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const { email, password, name, lastName } = formData;
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await setDoc(doc(firestore, 'users', user.uid), { email, name, lastName });

      const guestCart = JSON.parse(localStorage.getItem('cart')) || [];
      await saveCart(guestCart);
      setCart(guestCart);
      localStorage.removeItem('cart');

      navigate('/products');
    } catch (error) {
      console.error('Error registering user:', error);
    }
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${registerBackground})` }}>

      <div className={styles.formWrapper}>
        <h2>Crear Cuenta</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Apellidos"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Contraseña"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirmar Contraseña"
              required
            />
          </div>
          <button type="submit" className={styles.btnPrimary}>Registrarse</button>
        </form>
      </div>
    </div>
  );
};

export default CustomerRegister;
