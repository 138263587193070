import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { loadOrderHistory } from '../services/firestoreService';
import { Link } from 'react-router-dom';
import styles from './OrderHistory.module.css'; // Import the CSS module

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const unsubscribe = loadOrderHistory((ordersData) => {
      // Sort the orders by date, newest to oldest
      const sortedOrders = ordersData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setOrders(sortedOrders);
    });

    return () => unsubscribe();
  }, []);

  // Helper function to format currency in Costa Rican colones (₡)
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div className={styles.orderHistoryContainer}>
      <h2 className={styles.orderHistoryTitle}>Order History</h2>
      {orders.length > 0 ? (
        <ul className={styles.orderListGroup}>
          {orders.map((order) => (
            <li key={order.id} className={styles.orderListItem}>
              <h5 className={styles.orderNumber}>
                Order #{order.orderNumber || order.id}
              </h5>
              <p className={styles.orderText}>Date: {new Date(order.date).toLocaleDateString()}</p>
              <p className={styles.orderText}>Status: {order.status}</p>
              <p className={styles.orderText}>Payment Status: {order.paymentStatus || 'Pago Pendiente'}</p>
              <ul className={styles.orderItemList}>
                {order.items.map((item, itemIndex) => (
                  <li key={itemIndex} className={styles.orderItem}>
                    {item.name} - {formatCurrency(item.price)} ({item.quantity} units)
                  </li>
                ))}
              </ul>
              <p className={styles.orderTotal}>Total: {formatCurrency(order.total)}</p>
            </li>
          ))}
        </ul>
      ) : (
        <div>
          <p>No previous orders found.</p>
          <p><Link to="/products" className={styles.orderLink}>Browse products</Link> and place your first order!</p>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
