import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useCart } from '../contexts/CartContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Container } from 'react-bootstrap';
import Modal from 'react-modal';
import styles from './NavBar.module.css';
import logo from '../images/logonavbar.png';
import cartNumberFlower from '../images/cartnumberflower.png';

Modal.setAppElement('#root');

const NavBar = ({ user, userName, toggleCartSidebar, setIsCartSidebarOpen }) => {
  const navigate = useNavigate();
  const { cart } = useCart();
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expanded, setExpanded] = useState(false);

  const checkAdmin = async () => {
    if (user) {
      const firestore = getFirestore();
      const q = query(collection(firestore, 'admins'), where('email', '==', user.email));
      const querySnapshot = await getDocs(q);
      setIsAdmin(!querySnapshot.empty);
    } else {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    checkAdmin();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsCartSidebarOpen(false);
      setIsAdmin(false);
      navigate('/');
      setExpanded(false);
    } catch (err) {
      console.error('Failed to sign out:', err);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/products?search=${searchQuery}`);
      setExpanded(false);
    }
  };

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  const handleCartClick = () => {
    toggleCartSidebar();
    setExpanded(false); // Collapse navbar when "Carrito" is clicked
  };

  return (
    <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} bg="light" expand="lg" className={styles.navbar}>
      <Container>
        <Navbar.Brand as={Link} to="/" className={styles.navbarBrand} onClick={handleNavLinkClick}>
          <img src={logo} alt="Floristeria Armony" className={styles.navbarLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {user && (
              <Nav.Link className={styles.navLink} onClick={handleNavLinkClick}>Hola, {userName}</Nav.Link>
            )}
            <Nav.Link as={Link} to="/products" className={styles.navLink} onClick={handleNavLinkClick}>Productos</Nav.Link>
            <Nav.Link as={Link} to="/eventos" className={styles.navLink} onClick={handleNavLinkClick}>Eventos</Nav.Link>
            {user ? (
              <>
                <Nav.Link as={Link} to="/customer/profile" className={styles.navLink} onClick={handleNavLinkClick}>Perfil</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/customer/login" className={styles.navLink} onClick={handleNavLinkClick}>Login</Nav.Link>
                <Nav.Link as={Link} to="/customer/register" className={styles.navLink} onClick={handleNavLinkClick}>Registrarse</Nav.Link>
              </>
            )}
            <Nav.Link onClick={handleCartClick} className={styles.navLink} id="cart-icon">
              Carrito
              <div className={styles.cartIconContainer}>
                <img src={cartNumberFlower} alt="Cart Number Flower" className={styles.cartNumberFlower} />
                <span className={styles.cartNumber}>{cart.length}</span>
              </div>
            </Nav.Link>
            {isAdmin && (
              <NavDropdown title="Admin" id="admin-dropdown">
                <NavDropdown.Item as={Link} to="/admin/products" onClick={handleNavLinkClick}>Manejo de Productos</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/management" onClick={handleNavLinkClick}>Manejo de Admins</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/homepage-content" onClick={handleNavLinkClick}>Manejo de Homepage</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/eventos-content" onClick={handleNavLinkClick}>Manejo de Eventos</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/popup-management" onClick={handleNavLinkClick}>Manejo de Popups</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/orders" onClick={handleNavLinkClick}>Manejo de Ordenes</NavDropdown.Item>
              </NavDropdown>
            )}
            {user && (
              <Nav.Link onClick={handleSignOut} className={styles.navLink}>Salir</Nav.Link>
            )}
          </Nav>
          <Form className="d-flex ml-auto" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Buscar..."
              className={`${styles.formControl} mr-2`}
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button variant="outline-success" type="submit" className={styles.btnOutlineSuccess}>Buscar</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
