import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Modal from 'react-modal';
import Swal from 'sweetalert2'; // Import SweetAlert2
import styles from './AdminHomepageContent.module.css';

const AdminHomepageContent = () => {
  const [contentList, setContentList] = useState([]);
  const [activeContent, setActiveContent] = useState('');
  const [newContent, setNewContent] = useState({ id: '', title: '', description: '', heroImage: '', heroText: '', sections: [], nombreHomepage: '' });
  const [categories, setCategories] = useState([]);
  const [heroImageFile, setHeroImageFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false); // General loading state
  const [imageLoading, setImageLoading] = useState(false); // Image-specific loading state
  const firestore = getFirestore();
  const storage = getStorage();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchContentList = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'homepageContent'));
      const contents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const filteredContents = contents.filter(content => content.id !== 'current');
      setContentList(filteredContents);

      const currentContentDoc = await getDoc(doc(firestore, 'homepageContent', 'current'));
      if (currentContentDoc.exists()) {
        const activeContentId = currentContentDoc.data().activeContent;
        if (activeContentId) {
          const activeContentDoc = await getDoc(doc(firestore, 'homepageContent', activeContentId));
          if (activeContentDoc.exists()) {
            setActiveContent(activeContentId);
          } else {
            console.warn(`Active content document with ID ${activeContentId} does not exist.`);
          }
        }
      }
      const categorySnapshot = await getDocs(collection(firestore, 'categories'));
      const categoriesList = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoriesList);
    };
    fetchContentList();
  }, [firestore]);

  const handleSetActiveContent = async (id) => {
    setLoading(true); // Show loading overlay
    try {
      const activeContentDoc = await getDoc(doc(firestore, 'homepageContent', id));
      if (activeContentDoc.exists()) {
        await setDoc(doc(firestore, 'homepageContent', 'current'), { activeContent: id });
        setActiveContent(id);
        Swal.fire('Éxito', '¡Establecido como activa exitosamente!', 'success');
      } else {
        console.error(`Document with ID ${id} does not exist.`);
      }
    } catch (error) {
      Swal.fire('Error', 'Error al establecer la vista como activa.', 'error');
    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  const handleDisableActiveContent = async () => {
    setLoading(true); // Show loading overlay
    try {
      await setDoc(doc(firestore, 'homepageContent', 'current'), { activeContent: '' });
      setActiveContent(''); // Clear active content in the UI
      Swal.fire('Éxito', '¡Contenido de la página de inicio desactivado exitosamente!', 'success');

    } catch (error) {
      Swal.fire('Error', 'Error al desactivar el contenido de la página de inicio.', 'error');

    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  const handleAddOrUpdateContent = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    if (!newContent.title || !newContent.description || !newContent.nombreHomepage) {
      Swal.fire('Error', 'Título, Descripción y Nombre de la Página de Inicio son obligatorios.', 'error');

      return;
    }

    setLoading(true); // Show loading overlay
    try {
      let heroImageUrl = newContent.heroImage;

      // If a new image file is uploaded, upload it and replace the current image
      if (heroImageFile) {
        const storageRef = ref(storage, `homepage/${heroImageFile.name}`);
        setImageLoading(true); // Start image-specific loading
        await uploadBytes(storageRef, heroImageFile);
        heroImageUrl = await getDownloadURL(storageRef); // Get the new image URL
        setImageLoading(false); // End image-specific loading
      }

      const contentId = newContent.id || new Date().toISOString(); // Generate a unique ID for new content
      const content = { ...newContent, id: contentId, heroImage: heroImageUrl };
      const docRef = doc(firestore, 'homepageContent', contentId);
      await setDoc(docRef, content);

      setNewContent({ id: '', title: '', description: '', heroImage: '', heroText: '', sections: [], nombreHomepage: '' });
      setHeroImageFile(null);
      fileInputRef.current.value = null;

      const querySnapshot = await getDocs(collection(firestore, 'homepageContent'));
      const contents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContentList(contents.filter(content => content.id !== 'current'));

      closeModal(); // Close the modal after the content is saved
      Swal.fire('Éxito', newContent.id ? '¡Contenido actualizado exitosamente!' : '¡Contenido agregado exitosamente!', 'success');
    } catch (error) {
      Swal.fire('Error', 'Error al guardar el contenido.', 'error');
    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  const handleEditContent = (content) => {
    setNewContent(content);
    setModalIsOpen(true);
  };

  const handleDeleteContent = (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás deshacer esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, ¡elimínalo!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContent(id); // Call the deletion logic if confirmed
      }
    });
  };

  const deleteContent = async (id) => {
    setLoading(true); // Show loading overlay
    try {
      await deleteDoc(doc(firestore, 'homepageContent', id));
      const content = contentList.find(content => content.id === id);
      if (content && content.heroImage) {
        const imageRef = ref(storage, content.heroImage);
        await deleteObject(imageRef);
      }
      const querySnapshot = await getDocs(collection(firestore, 'homepageContent'));
      const contents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContentList(contents.filter(content => content.id !== 'current'));
      Swal.fire('Eliminado', 'Tu contenido ha sido eliminado.', 'success');
    } catch (error) {
      Swal.fire('Error', 'Hubo un error al eliminar el contenido.', 'error');
    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewContent({ id: '', title: '', description: '', heroImage: '', heroText: '', sections: [], nombreHomepage: '' });
    setHeroImageFile(null);
    fileInputRef.current.value = null;
  };

  const addSection = (e) => {
    e.preventDefault();
    setNewContent((prevContent) => ({
      ...prevContent,
      sections: [...prevContent.sections, { title: '', type: 'normal', content: {} }]
    }));
  };

  const updateSection = (index, updatedSection) => {
    const updatedSections = newContent.sections.map((section, idx) => (idx === index ? updatedSection : section));
    setNewContent((prevContent) => ({
      ...prevContent,
      sections: updatedSections
    }));
  };

  const removeSection = (index) => {
    setNewContent(prevContent => {
      const updatedSections = prevContent.sections.filter((_, idx) => idx !== index);
      return {
        ...prevContent,
        sections: updatedSections
      };
    });
  };

  const handleSectionImageUpload = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `homepageSections/${file.name}`);
      setImageLoading(true); // Start loading spinner for section image upload
      uploadBytes(storageRef, file).then(async (snapshot) => {
        const downloadURL = await getDownloadURL(snapshot.ref);
        const updatedSection = {
          ...newContent.sections[index],
          content: {
            ...newContent.sections[index].content,
            image: downloadURL
          }
        };
        updateSection(index, updatedSection);
        setImageLoading(false); // End loading spinner for section image upload
      });
    }
  };
  const [newLinkInput, setNewLinkInput] = useState({ platform: 'instagram', link: '' });


  const handleSectionCarouselImagesUpload = (index, e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const uploadPromises = [];
      const imageUrls = newContent.sections[index].content.images || [];
      setImageLoading(true); // Start loading spinner for carousel images
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const storageRef = ref(storage, `homepageSections/${file.name}`);
        const uploadTask = uploadBytes(storageRef, file)
          .then(snapshot => getDownloadURL(snapshot.ref))
          .then(url => imageUrls.push(url));
        uploadPromises.push(uploadTask);
      }
      Promise.all(uploadPromises).then(() => {
        const updatedSection = {
          ...newContent.sections[index],
          content: {
            ...newContent.sections[index].content,
            images: imageUrls
          }
        };
        updateSection(index, updatedSection);
        setImageLoading(false); // End loading spinner for carousel images
      });
    }
  };

  const handleDeleteCarouselImage = (sectionIndex, imageIndex) => {
    const updatedImages = [...newContent.sections[sectionIndex].content.images];
    updatedImages.splice(imageIndex, 1); // Remove the selected image
    const updatedSection = {
      ...newContent.sections[sectionIndex],
      content: {
        ...newContent.sections[sectionIndex].content,
        images: updatedImages
      }
    };
    updateSection(sectionIndex, updatedSection);
  };

  const addPublicationLink = (index) => {
    const formattedLink = formatLink(newLinkInput.platform, newLinkInput.link);
  
    setNewContent((prevContent) => {
      const updatedSections = [...prevContent.sections];
      const section = updatedSections[index];
  
      // Ensure links array is initialized if not present
      section.content.links = section.content.links || [];
  
      // Log current links and the link we're trying to add
      console.log("Attempting to add:", formattedLink);
      console.log("Current links array:", section.content.links);
  
      // Check for duplicates only if there are existing links
      const isDuplicate = section.content.links.some(
        (existingLink) => existingLink.link === formattedLink
      );
  
      if (isDuplicate) {
        console.log("Duplicate found:", formattedLink);
        Swal.fire("Error", "This link already exists.", "error");
        return prevContent; // Return the original state without adding the link
      }
  
      // Add the link if it's not a duplicate
      section.content.links.push({ platform: newLinkInput.platform, link: formattedLink });
  
      // Reset newLinkInput after adding
      setNewLinkInput({ platform: 'instagram', link: '' });
  
      console.log("Updated links array after addition:", section.content.links);
  
      return { ...prevContent, sections: updatedSections };
    });
  };
  
  
  
  

  const formatLink = (platform, link) => {
    let formattedLink = link.trim();
  
    if (platform === 'instagram' && !formattedLink.endsWith('/embed')) {
      formattedLink = `${formattedLink.replace(/\/$/, '')}/embed`;
    } else if (platform === 'tiktok' && !formattedLink.includes('/embed')) {
      const videoId = formattedLink.split('/').pop();
      formattedLink = `https://www.tiktok.com/embed/${videoId}`;
    }
  
    return formattedLink;
  };
  


  const removePublicationLink = (sectionIndex, linkIndex) => {
    const updatedLinks = [...newContent.sections[sectionIndex].content.links];
    updatedLinks.splice(linkIndex, 1);
    updateSection(sectionIndex, { ...newContent.sections[sectionIndex], content: { links: updatedLinks } });
  };

  return (
    <div className={styles.adminHomepageContent}>
      {loading && <div className={styles.loadingOverlay}></div>} {/* Loading Overlay */}
      {imageLoading && <div className={styles.imageLoadingOverlay}></div>} {/* Image Upload Loading Overlay */}
      <h2>Administración Homepage</h2>
  
      {/* Button to add new content */}
      <div className={styles.buttonRow}>
        <button onClick={() => setModalIsOpen(true)}>Agregar Nuevo Homepage</button>
      </div>
  
      {/* Display current active content */}
      <h3>Vista Actual Activa</h3>
      {contentList.filter(content => content.id === activeContent && content.nombreHomepage).map(content => (
        <div key={content.id}>
          <h4>{content.nombreHomepage}</h4> {/* Updated to display 'nombreHomepage' instead of title */}
          <button className={styles.disableButton} onClick={handleDisableActiveContent}>Deshabilitar</button>
          <button className={styles.editButton} onClick={() => handleEditContent(content)}>Editar</button>
          <button className={styles.deleteButton} onClick={() => handleDeleteContent(content.id)}>Eliminar</button>
        </div>
      ))}
  
      {/* Table for all content entries */}
      <h3>Todas las Vistas</h3>
      <table className={styles.contentTable}>
        <thead>
          <tr>
            <th>Nombre de la Vista</th> {/* Replaced Title with Nombre Homepage */}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {contentList.map((content) => (
            <tr key={content.id}>
              <td>{content.nombreHomepage}</td> {/* Show Nombre Homepage instead of title */}
              <td className="actions">
                <button
                  className={content.id === activeContent ? styles.activeButton : styles.setActiveButton}
                  onClick={() => handleSetActiveContent(content.id)}
                >
                  {content.id === activeContent ? 'Activa' : 'Activar'}
                </button>
                <button className={styles.editButton} onClick={() => handleEditContent(content)}>Editar</button>
                <button className={styles.deleteButton} onClick={() => handleDeleteContent(content.id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  
      {/* Modal for adding/updating content */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Homepage Content"
        className={styles.ReactModal__Content}
        overlayClassName={styles.ReactModal__Overlay}
      >
        <h2 className={styles.modalHeader}>{newContent.id ? 'Editar Vista' : 'Agregar Nueva Vista'}</h2>
        <div className={styles.modalBody}>
          <form onSubmit={handleAddOrUpdateContent}>
            <input
              type="text"
              value={newContent.nombreHomepage}
              onChange={(e) => setNewContent({ ...newContent, nombreHomepage: e.target.value })}
              placeholder="Nombre de la Vista (Solo Admin)"
              className={styles.inputField}
              required
            />
  
            <input
              type="text"
              value={newContent.title}
              onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
              placeholder="Título"
              className={styles.inputField}
              required
            />
  
            <textarea
              value={newContent.description}
              onChange={(e) => setNewContent({ ...newContent, description: e.target.value })}
              placeholder="Descripción"
              className={styles.textAreaField}
              required
            />
  
            {/* Hero Image Upload */}
            <label>Imágen Hero Portada</label>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => setHeroImageFile(e.target.files[0])}
              className={styles.inputFile}
            />
            {newContent.heroImage && (
              <img
                src={newContent.heroImage}
                alt="Current Hero Image"
                className={styles.imagePreview}
              />
            )}
  
            <input
              type="text"
              value={newContent.heroText}
              onChange={(e) => setNewContent({ ...newContent, heroText: e.target.value })}
              placeholder="Texto Hero"
              className={styles.inputField}
            />
  
            <h3>Secciones</h3>
            {newContent.sections.map((section, index) => (
              <div key={index} className={styles.section}>
                <input
                  type="text"
                  value={section.title}
                  onChange={(e) => updateSection(index, { ...section, title: e.target.value })}
                  placeholder="Título de Sección"
                />
                <select
                  value={section.type}
                  onChange={(e) => updateSection(index, { ...section, type: e.target.value, content: {} })}
                >
                  <option value="normal">Normal</option>
                  <option value="onImage">En Image</option>
                  <option value="carousel">Carrusel</option>
                  <option value="productList">Lista Productos</option>
                  <option value="reviews">Reviews</option>
                  <option value="text">Texto</option>
                  <option value="button">Botón</option>
                  <option value="socialMediaProfilesEmbed">Social Media Profiles Embed</option>
                  <option value="publications">Publications</option>
                </select>
  
                {/* Section Type: Social Media Profiles Embed */}
                {section.type === 'socialMediaProfilesEmbed' && (
                  <div>
                    <p>This section will display Instagram and Facebook profiles side by side.</p>
                  </div>
                )}
  
                {/* Section Type: Publications */}

{section.type === 'publications' && (
  <div>
    <h4>Publication Links</h4>
    {section.content.links && section.content.links.map((link, linkIndex) => (
      <div key={linkIndex}>
        <p>Platform: {link.platform}</p>
        <p>Link: {link.link}</p>
        <button type="button" onClick={() => removePublicationLink(index, linkIndex)}>Remove Link</button>
      </div>
    ))}


<select
    onChange={(e) => setNewLinkInput((prev) => ({ ...prev, platform: e.target.value }))}
    value={newLinkInput.platform}
  >
    <option value="instagram">Instagram</option>
    <option value="tiktok">TikTok</option>
  </select>
  <input
    type="text"
    placeholder="Enter link"
    value={newLinkInput.link}
    onChange={(e) => setNewLinkInput((prev) => ({ ...prev, link: e.target.value }))}
  />
  <button
    type="button"
    onClick={() => {
      if (newLinkInput.link) {
        addPublicationLink(index);
      }
    }}
  >
    Add Link
    </button>
  </div>
)}
  
                {/* Other Section Types */}
                {/* Section Type: Normal */}
                {section.type === 'normal' && (
                  <div>
                    <input
                      type="file"
                      onChange={(e) => handleSectionImageUpload(index, e)}
                    />
                    {section.content.image && (
                      <img
                        src={section.content.image}
                        alt="Current Section Image"
                        className={styles.imagePreview}
                      />
                    )}
                    <textarea
                      value={section.content.text || ''}
                      onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                      placeholder="Texto"
                    />
                  </div>
                )}
  
                {/* Section Type: On Image */}
                {section.type === 'onImage' && (
                  <div>
                    <input
                      type="file"
                      onChange={(e) => handleSectionImageUpload(index, e)}
                    />
                    {section.content.image && (
                      <img
                        src={section.content.image}
                        alt="Current Section Image"
                        className={styles.imagePreview}
                      />
                    )}
                    <textarea
                      value={section.content.text || ''}
                      onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                      placeholder="Text"
                    />
                    <input
                      type="color"
                      value={section.content.textColor || '#000000'}
                      onChange={(e) => updateSection(index, { ...section, content: { ...section.content, textColor: e.target.value } })}
                    />
                  </div>
                )}
  
                {/* Section Type: Carousel */}
                {section.type === 'carousel' && (
                  <div>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => handleSectionCarouselImagesUpload(index, e)}
                    />
                    <div className={styles.carouselImageGrid}>
                      {section.content.images &&
                        section.content.images.map((imageUrl, idx) => (
                          <div key={idx} className={styles.carouselImageContainer}>
                            <img
                              src={imageUrl}
                              alt={`Carousel Image ${idx + 1}`}
                              className={styles.imagePreview}
                            />
                            <button
                              type="button"
                              className={styles.deleteCarouselButton}
                              onClick={() => handleDeleteCarouselImage(index, idx)}
                            >
                              X
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
  
                {/* Section Type: Product List */}
                {section.type === 'productList' && (
                  <div>
                    <select
                      value={section.content.option || ''}
                      onChange={(e) => updateSection(index, { ...section, content: { ...section.content, option: e.target.value } })}
                    >
                      <option value="" disabled>Seleccionar Opción</option>
                      <option value="bestSelling">Más Vendidos</option>
                      <option value="recentlyAdded">Agregados Recientes</option>
                      {categories.map(category => (
                        <option key={category.id} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  </div>
                )}
  
                {/* Section Type: Reviews */}
                {section.type === 'reviews' && (
                  <div>
                    <p>Esta sección mostrará todas las reseñas de productos como tarjetas en un formato de carrusel.</p>
                  </div>
                )}
  
                {/* Section Type: Text */}
                {section.type === 'text' && (
                  <textarea
                    value={section.content.text || ''}
                    onChange={(e) => updateSection(index, { ...section, content: { ...section.content, text: e.target.value } })}
                    placeholder="Enter your text here"
                    rows={6} // Allow for multiple lines
                  />
                )}
  
                {/* Section Type: Button */}
                {section.type === 'button' && (
                  <div>
                    <p>Esta sección añadirá un botón a la página de inicio.</p>
                  </div>
                )}
  
                <button onClick={(e) => { e.preventDefault(); removeSection(index); }}>Remover Sección</button>
              </div>
            ))}
  
            <div className={styles.modalButtonRow}>
              <button type="button" className={styles.modalAddSectionButton} onClick={addSection}>Nueva Sección</button>
              <button
                type="submit"
                className={styles.modalSubmitButton}
                disabled={imageLoading} // Disable the button while image is uploading
              >
                {newContent.id ? 'Actualizar Vista' : 'Guardar Vista'}
              </button>
              <button type="button" className={styles.modalCloseButton} onClick={closeModal}>
                Cerrar
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
  

};

export default AdminHomepageContent;
