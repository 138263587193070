import React from 'react';
import styles from './WhatsAppButton.module.css';
import whatsappIcon from '../images/whatsapp-logo-4456.png'; // Import the WhatsApp logo image

const WhatsAppButton = ({ isCartOpen }) => {
  const handleClick = () => {
    window.open('https://wa.me/+50664037952', '_blank'); // Replace with your WhatsApp number
  };

  return (
    <div className={`${styles.whatsappContainer} ${isCartOpen ? styles.cartOpen : ''}`}>
      <div className={styles.chatBubble}>
        ¿Necesitas ayuda en algo? <br />
      </div>
      <div className={styles.whatsappButton} onClick={handleClick}>
        <img src={whatsappIcon} alt="WhatsApp" />
      </div>
    </div>
  );
};

export default WhatsAppButton;
