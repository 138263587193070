import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import { loadCart, saveCart } from '../services/firestoreService';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetchCart = async () => {
      if (auth.currentUser) {
        const cartData = await loadCart();
        setCart(cartData || []);
      } else {
        const savedCart = localStorage.getItem('cart');
        setCart(savedCart ? JSON.parse(savedCart) : []);
      }
    };

    fetchCart();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        // Clear the cart when the user logs out
        setCart([]);
        localStorage.removeItem('cart');
      }
    });

    return () => unsubscribe();
  }, []);

  const addToCart = async (product, onProductAdded) => {
    if (!product || !product.id || product.price === undefined || product.stock === undefined || product.quantity === undefined) {
      console.error('Invalid product data:', product);
      return;
    }
  
    const existingItem = cart.find((item) => {
      if (item.id === product.id) {
        if (item.option && product.option) {
          return item.option.name === product.option.name;
        }
        return !item.option && !product.option;
      }
      return false;
    });
  
    let updatedCart;
  
    const priceToUse = product.option ? product.option.price : product.price;
  
    if (existingItem) {
      updatedCart = cart.map((item) =>
        item === existingItem
          ? { ...item, quantity: Math.min(item.quantity + 1, item.stock) }
          : item
      );
    } else {
      updatedCart = [...cart, { ...product, price: priceToUse, quantity: 1 }];
    }
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    if (auth.currentUser) {
      try {
        await saveCart(updatedCart);
      } catch (err) {
        console.error('Failed to save cart:', err);
      }
    }
  
    // Trigger the callback to open the cart sidebar
    if (onProductAdded) {
      onProductAdded();
    }
  };
  
  

  const removeFromCart = async (productId, optionName) => {
    const updatedCart = cart.filter((item) => {
      // Remove the item that matches the productId and the option name if applicable
      if (item.id === productId) {
        if (item.option && optionName) {
          return item.option.name !== optionName;
        }
        return false; // Remove if no option is present
      }
      return true; // Keep items that don't match
    });

    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));

    if (auth.currentUser) {
      try {
        await saveCart(updatedCart);
      } catch (err) {
        console.error('Failed to save cart:', err);
      }
    }
  };

  const updateQuantity = async (productId, quantity, optionName) => {
    const updatedCart = cart.map((item) =>
      item.id === productId && (!item.option || item.option.name === optionName)
        ? { ...item, quantity: Math.min(Math.max(quantity, 1), item.stock) }
        : item
    );

    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));

    if (auth.currentUser) {
      try {
        await saveCart(updatedCart);
      } catch (err) {
        console.error('Failed to save cart:', err);
      }
    }
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateQuantity, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
