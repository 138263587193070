import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useCart } from '../contexts/CartContext';
import { saveCart, loadCart } from '../services/firestoreService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './CustomerLogin.module.css';  // Import the CSS module
import loginBackground from '../images/login.jpg'; // Import the background image

const errorMessages = {
  'auth/invalid-email': 'La dirección de correo electrónico no es válida.',
  'auth/user-disabled': 'La cuenta de usuario ha sido deshabilitada.',
  'auth/user-not-found': 'No se encontró ninguna cuenta con este correo electrónico.',
  'auth/wrong-password': 'La contraseña es incorrecta.',
  'auth/invalid-credential': 'Las credenciales no son válidas.',
  'default': 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.'
};

const CustomerLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setCart } = useCart();
  const auth = getAuth();
  const firestore = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);

      const guestCart = JSON.parse(localStorage.getItem('cart')) || [];
      const userCart = await loadCart();
      const mergedCart = mergeCarts(guestCart, userCart);

      await saveCart(mergedCart);
      setCart(mergedCart);
      localStorage.removeItem('cart');

      const q = query(collection(firestore, 'admins'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
      const isAdmin = !querySnapshot.empty;
      if (isAdmin) {
        navigate('/admin/management');
        toast.success('Inicio de sesión exitoso. ¡Bienvenido Admin!');
      } else {
        navigate('/products');
        toast.success('Inicio de sesión exitoso. ¡Bienvenido!');
      }
    } catch (error) {
      console.error("Error logging in:", error);
      const message = errorMessages[error.code] || errorMessages['default'];
      toast.error(message);
    }
  };

  const mergeCarts = (guestCart, userCart) => {
    const mergedCart = [...userCart];
    guestCart.forEach(guestItem => {
      const existingItem = mergedCart.find(item => item.id === guestItem.id);
      if (existingItem) {
        existingItem.quantity = Math.min(existingItem.quantity + guestItem.quantity, existingItem.stock);
      } else {
        mergedCart.push(guestItem);
      }
    });
    return mergedCart;
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${loginBackground})` }}>
      <ToastContainer />
      <div className={styles.formWrapper}>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className={styles.formGroup}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              required
            />
          </div>
          <button type="submit" className={styles.btnPrimary}>Login</button>
        </form>
        <div className={styles.footer}>
          <Link to="/forgot-password">Olvidaste la Contraseña?</Link>
        </div>
        <div className={styles.footer}>
          <Link to="/customer/register">No tienes una cuenta?</Link>
        </div>
      </div>
    </div>
  );
};

export default CustomerLogin;
