import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs, doc, setDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import Modal from 'react-modal';
import Swal from 'sweetalert2'; // Import SweetAlert2
import styles from './AdminPopupManagement.module.css'; // Import the CSS module

const AdminPopupManagement = () => {
  const [popups, setPopups] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const [newPopup, setNewPopup] = useState({ id: '', title: '', description: '', imageUrl: '', nombreHomepage: '', active: false });
  const [imageFile, setImageFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [deleteConfirm, setDeleteConfirm] = useState(null); // Delete confirmation modal state
  const firestore = getFirestore();
  const storage = getStorage();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchPopups = async () => {
      setLoading(true); // Start loading
      const querySnapshot = await getDocs(collection(firestore, 'popups'));
      const popupList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPopups(popupList);
      setLoading(false); // End loading

      const activePopupDoc = popupList.find(popup => popup.active);
      if (activePopupDoc) {
        setActivePopup(activePopupDoc.id);
      }
    };

    fetchPopups();
  }, [firestore]);

  const handleSetActivePopup = async (id, isActive) => {
    setLoading(true); // Show loading overlay
    const batch = writeBatch(firestore);

    const currentActivePopup = popups.find(popup => popup.active);
    if (currentActivePopup && currentActivePopup.id !== id) {
      batch.update(doc(firestore, 'popups', currentActivePopup.id), { active: false });
    }

    batch.update(doc(firestore, 'popups', id), { active: !isActive });
    await batch.commit();
    setActivePopup(!isActive ? id : null);

    const updatedPopups = await getDocs(collection(firestore, 'popups'));
    setPopups(updatedPopups.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    setLoading(false); // Hide loading overlay

    Swal.fire({
      title: isActive ? '¡Popup Deshabilitado!' : '¡Popup Activado!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const handleAddOrUpdatePopup = async () => {
    setLoading(true); // Show loading overlay
    let imageUrl = newPopup.imageUrl;

    if (imageFile) {
      const storageRef = ref(storage, `popups/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(storageRef);
    }

    const popupId = newPopup.id || new Date().toISOString();
    const popup = { ...newPopup, id: popupId, imageUrl, active: false };

    await setDoc(doc(firestore, 'popups', popupId), popup);

    setNewPopup({ id: '', title: '', description: '', imageUrl: '', nombreHomepage: '', active: false });
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    setModalIsOpen(false);

    const updatedPopups = await getDocs(collection(firestore, 'popups'));
    setPopups(updatedPopups.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    setLoading(false); // Hide loading overlay

    Swal.fire({
      title: newPopup.id ? '¡Popup Actualizado!' : '¡Popup Agregado!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const handleEditPopup = (popup) => {
    setNewPopup(popup);
    setModalIsOpen(true);
  };

  const handleDeletePopup = (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás deshacer esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, ¡elimínalo!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true); // Show loading overlay
        await deleteDoc(doc(firestore, 'popups', id));

        const popup = popups.find(popup => popup.id === id);
        if (popup && popup.imageUrl) {
          const imageRef = ref(storage, popup.imageUrl);
          await deleteObject(imageRef);
        }

        const updatedPopups = await getDocs(collection(firestore, 'popups'));
        setPopups(updatedPopups.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLoading(false); // Hide loading overlay

        Swal.fire('Eliminado!', 'Tu popup ha sido eliminado.', 'success');
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPopup({ id: '', title: '', description: '', imageUrl: '', nombreHomepage: '', active: false });
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div className={styles.popupManagementContainer}>
      {loading && <div className={styles.loadingOverlay}></div>} {/* Loading Overlay */}

      <h2 className={styles.popupTitle}>Admin: Manejo de Popups</h2>

      {/* Add New Popup Button */}
      <div className={styles.centeredButtonRow}>
        <button onClick={() => setModalIsOpen(true)} className={styles.popupButton}>
          Agregar Popup
        </button>
      </div>

      <div className={styles.centeredSection}>
        <h3 className={styles.sectionTitle}>Popup Activo</h3>
        {popups.filter(popup => popup.id === activePopup).map(popup => (
          <div key={popup.id} className={styles.popupItem}>
            <h4>{popup.nombreHomepage}</h4> {/* Display nombreHomepage instead of title */}
            <button className={`${styles.popupButton} ${styles.disableButton}`} onClick={() => handleSetActivePopup(popup.id, true)}>
              Deshabilitar
            </button>
            <button className={`${styles.popupButton} ${styles.editButton}`} onClick={() => handleEditPopup(popup)}>Editar</button>
            <button className={`${styles.popupButton} ${styles.deleteButton}`} onClick={() => handleDeletePopup(popup.id)}>Eliminar</button>
          </div>
        ))}
      </div>

      <div className={styles.centeredSection}>
        <h3 className={styles.sectionTitle}>Lista de Popups</h3>
        <div className={styles.popupTable}>
          <div className={styles.popupTableRowHeader}>
            <div className={styles.popupTableHeader}>Nombre Popup</div>
            <div className={styles.popupTableHeader}>Acciones</div>
          </div>
          {popups.map(popup => (
            <div key={popup.id} className={styles.popupTableRow}>
              <div className={styles.popupTableCell}>{popup.nombreHomepage}</div> {/* Show Nombre Homepage */}
              <div className={styles.popupTableCell}>
                <button className={`${styles.popupButton} ${styles.activeButton}`} onClick={() => handleSetActivePopup(popup.id, false)}>
                  Activar
                </button>
                <button className={`${styles.popupButton} ${styles.editButton}`} onClick={() => handleEditPopup(popup)}>Editar</button>
                <button className={`${styles.popupButton} ${styles.deleteButton}`} onClick={() => handleDeletePopup(popup.id)}>Eliminar</button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Popup"
        className={styles.ReactModal__Content}
        overlayClassName={styles.ReactModal__Overlay}
      >
        <h2 className={styles.modalHeader}>{newPopup.id ? 'Editar Popup' : 'Agregar Nuevo Popup'}</h2>
        <div className={styles.modalBody}>
          <form>

          <input
              type="text"
              value={newPopup.nombreHomepage}
              onChange={(e) => setNewPopup({ ...newPopup, nombreHomepage: e.target.value })}
              placeholder="Nombre Popup (Solo Admin)"
              className={styles.inputField}
            />

            <input
              type="text"
              value={newPopup.title}
              onChange={(e) => setNewPopup({ ...newPopup, title: e.target.value })}
              placeholder="Título"
              className={styles.inputField}
            />
            <label>Imágen Popup</label>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => setImageFile(e.target.files[0])}
              className={styles.inputFile}
            />
            <textarea
              value={newPopup.description}
              onChange={(e) => setNewPopup({ ...newPopup, description: e.target.value })}
              placeholder="Descripción"
              className={styles.textAreaField}
            />

            <button type="button" className={styles.popupButton} onClick={handleAddOrUpdatePopup}>
              {newPopup.id ? 'Actualizar Popup' : 'Guardar Popup'}
            </button>
            <button type="button" className={styles.modalCloseButton} onClick={closeModal}>Cerrar</button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AdminPopupManagement;
